
import { Book } from './book.model'
import { Accumulation } from './accumulation.model'
import { IBook } from './instance.model';

export class Liability extends Accumulation {

    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, startPeriodNumber: number = 0) {
        super(name, book, -1, initialAmount, changeRate, true, startPeriodNumber);
        this.category = 'Liability';
        this.flowType = 'Liability';
        this.preventSignChange = true;
    }
    // flowFrom(v: number, flow: Flow) {
    //     return super.flowFrom(-v, flow);
    // }




}
export class MonthlyRestLoan extends Liability {

    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, startPeriodNumber: number = 0) {
        super(name, book, initialAmount, changeRate, startPeriodNumber);
        // this.category = 'Monthly Rest Loan';
    }
}
export class FlatRateLoan extends Liability {
    private tenure: number;

    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, tenure: number = 0, startPeriodNumber: number = 0) {
        super(name, book, initialAmount, changeRate, startPeriodNumber);
        // this.category = 'Flat Rate Loan';
        this.tenure = tenure;
    }
    /// 
    process() {
        // Flat Rate Loan does not grow; interest is already included in the amount at init()
    }
    /// process() and init() must return the same export class 
    init() {
        this.transactions = []; // clear as this is a current state object
        // flat rate calculation: set the amount at begining to loan amount * tenure * interest
        const newAmount = this.initialAmount + (this.initialAmount * this.tenure * this.changeRate);
        this.amount = newAmount;
    }
    getStateSchema() {
        const schema = super.getStateSchema();
        schema['tenure'] = {
            type: 'integer',
            title: 'Tenure',
            required: true,
            minimum: 0,
            maximum: +9999
        };
        return schema;
    }
    getStateValue() {
        const values = super.getStateValue();
        values['tenure'] = this.tenure;
        return values;
    }
    setStateValue(values) {
        super.setStateValue(values);
        if (values.tenure !== undefined) {
            this.tenure = values.tenure;
        }


    }
}