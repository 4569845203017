
import { Guid } from 'guid-typescript';
import { Exclude } from 'class-transformer';
export interface IBook extends PersistableInstance {
    // getSchedule();
    getCash();
}

export interface PersistableInstance {
    getStateSchema();
    getStateValue();
    setStateValue(values);
}
export abstract class IInstance implements PersistableInstance {
    public uuid: string;
    protected name: string;
    protected active = true;

    @Exclude()
    protected book: IBook;
    protected startPeriodNumber: number;
    protected endPeriodNumber: number;
    protected changeRate: number;
    protected initialAmount: number;
    @Exclude()
    protected amount: number;
    protected category: string;
    @Exclude()
    protected transactions: Array<any>;
    protected color: string;
    protected flowType: string;
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0,
        endPeriodNumber: number = 0, changeRate: number = 0) {
        // if (name === null) {
        //     throw new Error('Null argument: ' + 'name');
        // }

        this.name = name;
        this.book = book;
        this.initialAmount = initialAmount;

        this.amount = initialAmount;


        this.changeRate = changeRate;
        if (startPeriodNumber) {
            this.setStartPeriod(startPeriodNumber);
        }
        this.setEndPeriod(endPeriodNumber);

        this.uuid = Guid.create().toString();
    }

    getId() {
        return this.uuid;
    }
    setId(id: string) {
        this.uuid = id;
    }
    getName() {
        return this.name;
    }
    getAmount() {
        return this.amount;
    }
    getCategory() {
        return this.category;
    }
    getColor() {
        return this.color;
    }
    getGenericType() {
        return this.flowType;
    }
    isActive() {
        return this.active;
    }
    setColor(value: string) {
        this.color = value;
    }
    setStartPeriod(periodNumber: number) {
        //     if (periodNumber < this.book.schedule.getYear(0)) {
        //         throw ('Invalid startPeriodNumber');
        //     }
        //     if (periodNumber > this.book.schedule.getEndYear()) {
        //         throw ('Invalid startPeriodNumber');
        //     }
        this.startPeriodNumber = periodNumber;
    }

    setEndPeriod(periodNumber: number) {
        // if (periodNumber < this.book.schedule.getYear(0)) {
        //     throw ('Invalid endPeriodNumber');
        // }
        // if (periodNumber > this.book.schedule.getEndYear()) {
        //     throw ('Invalid endPeriodNumber');
        // }
        this.endPeriodNumber = periodNumber;

    }
    setActive(value: boolean) {
        this.active = value;
    }


    abstract beforeProcess(previousState, currentPeriodNumber: number, retirementPeriod: number): void;
    abstract process(currentPeriodNumber: number, retirementPeriod: number): void;
    abstract init(): void;

    getStateSchema() {
        return {
            uuid: {
                type: 'string',
                title: 'ID',
                readonly: true
            },
            name: {
                type: 'string',
                title: 'Name',
                required: true,
                minLength: 3,
                maxLength: 50
            },
            initialAmount: {
                type: 'number',
                title: 'Initial Amount',
                required: true,
                minimum: -999999999,
                maximum: +999999999
            },
            startPeriodNumber: {
                type: 'integer',
                title: 'Start Period',
                required: true,
                referenceType: 'Schedule',
                minimum: 2000,
                maximum: 10000
            },
            endPeriodNumber: {
                type: 'integer',
                title: 'End Period',
                required: true,
                referenceType: 'Schedule',
                minimum: 2000,
                maximum: 10000
            },
            changeRate: {
                type: 'number',
                title: 'Change Rate',
                // step: 0.001,
                multipleOf: 0.001,
                required: true,
                multipleOfPrecision: 1
                // Xminimum: -10,
                // Xmaximum: +10
            }
        };
    }
    getStateValue() {
        return {
            _class: this.constructor.name,
            uuid: this.uuid,
            name: this.name,
            changeRate: this.changeRate,
            initialAmount: this.initialAmount,
            startPeriodNumber: this.startPeriodNumber,
            endPeriodNumber: this.endPeriodNumber,
        };
    }
    setStateValue(values) {
        // console.log(values);
        if (values.uuid !== undefined) {
            this.uuid = values.uuid;
        }
        if (values.name !== undefined) {
            this.name = values.name;
        }
        if (values.startPeriodNumber !== undefined) {
            this.setStartPeriod(values.startPeriodNumber);
        }
        if (values.endPeriodNumber !== undefined) {
            this.setEndPeriod(values.endPeriodNumber);
        }
        if (values.changeRate !== undefined) {
            this.changeRate = values.changeRate;
        }
        if (values.initialAmount !== undefined) {
            this.initialAmount = values.initialAmount;
        }

    }
    getAdjustedStartPeriodNumber(retirementPeriod: number) {
        if (this.startPeriodNumber === 10000) {
            return retirementPeriod;
        } else {
            return this.startPeriodNumber;
        }
    }
    getAdjustedEndPeriodNumber(retirementPeriod: number) {
        if (this.endPeriodNumber === 10000) {
            return retirementPeriod;
        } else {
            return this.endPeriodNumber;
        }
    }
  
}
