import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ModuleWithProviders } from '@angular/core';
import { NotFoundComponent } from './not-found.component/not-found.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },




    {
        path: 'home',
        component: HomeComponent
    },



    { path: 'details', loadChildren: './details/details.module#DetailsModule' },
    { path: 'plan', loadChildren: './plan/plan.module#PlanModule' },
    { path: 'sample', loadChildren: './sample/sample.module#SampleModule' }, // https://github.com/angular/angular-cli/issues/10673#issuecomment-391786453
    { path: '**', component: NotFoundComponent }
]

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);