import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { routing } from './app.routing';
// import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { HomeComponent } from './home/home.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { AlertModule } from 'ngx-bootstrap/alert';

import { Bootstrap3FrameworkModule, MaterialDesignFrameworkModule } from 'angular6-json-schema-form';



import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';




import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { CellCheckBoxService } from './shared/cellCheckBoxRender/cell-checkbox-render.component';

import { NgxSpinnerModule } from 'ngx-spinner';

// import { ArchwizardModule } from 'angular-archwizard';
// import { StartComponent } from './start/start.component';
import { CoreModule } from './core/core.module';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import { SidebarModule } from 'primeng/sidebar';
import { SharedModule } from './shared/shared.module';
import { NotFoundComponent } from './not-found.component/not-found.component';
 

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
@NgModule({
  declarations: [
    AppComponent,
   

  
    // ProfileComponent,
    HomeComponent,

    NotFoundComponent
    // StartComponent,
   


  ],
  imports: [
    BrowserModule,
    // RouterModule.forRoot(AppRoutes),
    CoreModule.forRoot(),
    routing,
    SidebarModule,
    NavbarModule,
    FooterModule,
        FixedPluginModule,
    // NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=YOUR_KEY_HERE'})
  
    // HttpClientModule,

    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    ButtonsModule.forRoot(),
    AccordionModule.forRoot(),
    SortableModule.forRoot(),
    AlertModule.forRoot(),

    MaterialDesignFrameworkModule,
    Bootstrap3FrameworkModule,


    ModalModule.forRoot(),

    FormsModule,
    NgSelectModule,

    // https://itnext.io/step-by-step-complete-firebase-authentication-in-angular-2-97ca73b8eb32
    AngularFireModule.initializeApp(environment.firebase, 'angular-auth-firebase'),
    // AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,

    NgxSpinnerModule,
    BootstrapModalModule.forRoot({container:document.body}),
    SharedModule,
    LoadingBarRouterModule
    // ArchwizardModule

  ],
  providers: [CellCheckBoxService],
  bootstrap: [AppComponent]
})
export class AppModule { }
