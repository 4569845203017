import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
// https://github.com/akveo/ng2-smart-table/issues/77
/**
*
*/
export class CheckBoxEventModel {
  rowData: any;
  checked: any;
}

/**
*
*/
export class CellCheckBoxService {
  public checkboxChange = new EventEmitter();
}

/**
*
*/
@Component({
  selector: 'cell-checkbox',
  template: `<input type="checkbox" (change)="onToggle($event)" name = "onoffswitch" [checked] = "this.isChecked" [isDisabled] = "rowData.mode === 'readonly' id="myonoffswitch">`
})

/**
*
*/
export class CellCheckBoxRenderComponent implements OnInit {

  public isChecked: boolean;
  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save = new EventEmitter();

  /**
  *
  
  @param checkBoxService
  */
  constructor(
    private checkBoxService: CellCheckBoxService
  ) { }
  /**
  *
  */
  ngOnInit() {

    let flag = false;

    if (this.value) {
      flag = true;
    }
    this.isChecked = flag;
  }

  /**
  *
  
  @param value
  */
  onToggle(value) {
    this.isChecked = !this.isChecked;
    this.checkBoxService.checkboxChange.emit({
      rowData: this.rowData,
      checked: this.isChecked
    });
  }
}

