import { AssertNotNull } from '@angular/compiler';
import { Exclude, Type, Transform } from 'class-transformer';

import { firestore } from 'firebase';
export class Schedule {
    public name: string;
    public startYear: number;
    @Type(() => firestore.Timestamp)
    @Transform(function (value: firestore.Timestamp) {
        // Class Transformer: @Transformer: https://github.com/typestack/class-transformer#basic-usage
        // https://github.com/typestack/class-transformer/issues/174
        // Firebase TimeStamp: https://firebase.google.com/docs/reference/android/com/google/firebase/Timestamp
        console.log(value);
        return value.toDate();
    }, { toClassOnly: true })
    public startDate: Date;
    public periodCount: number;
    @Type(() => firestore.Timestamp)
    @Transform(function (value: firestore.Timestamp) {
        return value.toDate();
    }, { toClassOnly: true })
    public birthDate: Date;
    @Exclude()
    private periods: Array<Period> = null;
    constructor(name: string, startYear: number, birthDate: Date, periodCount: number) {
        this.name = name;
        this.setStartYear(startYear);
        this.setStartDate(new Date(startYear, 0, 1));
        this.setPeriodCount(periodCount);
        this.setBirthDate(birthDate);
        this.periodCount = periodCount;
    }
    init() {
        this.periods = [];
        for (let i = 0; i < this.periodCount; i++) {
            const date = new Date(this.startDate.getFullYear() + i, 0, 1);
            this.periods.push(new Period(`${date.getFullYear()}`, date, date.getFullYear(), date.getFullYear() - this.birthDate.getFullYear()));
        }
    }
    getPeriods() {
        if (this.periods == null) {
            this.init();
        }
        return this.periods;
    }
    private setStartDate(startDate: Date) {
        this.startDate = startDate;
    }
    private setStartYear(startYear: number) {
        this.startYear = startYear;
    }
    private setPeriodCount(periodCount: number) {
        this.periodCount = periodCount;
    }
    private setBirthDate(birthDate: Date) {
        this.birthDate = birthDate;
    }
    // Adding a method to the constructor
    toString() {
        return `Schedule: ${this.name}, startDate: ${this.startDate}.`;
    }

    getYear(index: number) {
        return this.startYear + index;
    }
    getEndYear() {
        return this.startYear + this.periodCount - 1;
    }
    getYearByAge(age: number) {
        const year = this.birthDate.getFullYear() + age;
        if (year < this.startYear || year > this.getEndYear()) {
            throw new Error('Invalid age for schedule: ' + age);
        }
        return year;
    }
    getStateValue() {
        return {
            name: this.name,
            startYear: this.startYear,
            startDate: this.startDate,
            periodCount: this.periodCount,
            birthDate: this.birthDate
        };
    }
    // TODO: generates the periods?
    setStateValue(values: any) {
        if (values.name !== undefined) {
            this.name = values.name;
        }
        if (values.startYear !== undefined) {
            this.startYear = values.startYear;
        }
        if (values.periodCount !== undefined) {
            this.periodCount = values.periodCount;
        }
        if (values.startDate !== undefined) {
            this.startDate = new Date(Date.parse(values.startDate));
        }
        if (values.birthDate !== undefined) {
            this.birthDate = new Date(Date.parse(values.birthDate));
        }

    }
}


export class Period {
    public name: string;
    public date: Date;
    public year: number;
    public age: number;
    constructor(name: string, date: Date, year: number, age: number) {
        this.name = name;
        this.date = date;
        this.year = year;
        this.age = age;
    }

    // Adding a method to the constructor
    toString() {
        return `Age: ${this.age}, year: ${this.year}.`;
    }
}
