
import { Profile } from './profile.model'
import { Book } from './book.model'
import { Timeline } from './timeline.model'
import { Schedule, Period } from './schedule.model'
import { Exclude, Type, Transform } from 'class-transformer';
import { firestore } from 'firebase';

// export class Planner {
//     private person: Profile;
//     constructor(person: Profile) {
//         this.person = person;
//     }

// }


export class Plan {


    private name: string;
    @Exclude()
    private person: Profile;

    @Type(() => firestore.Timestamp)
    @Transform(function (value: firestore.Timestamp) {
        return value.toDate();
    }, { toClassOnly: true })
    public birthDate: Date;
    @Type(() => firestore.Timestamp)
    @Transform(function (value: firestore.Timestamp) {
        return value.toDate();
    }, { toClassOnly: true })
    private startDate: Date;
    private initialAmount = 0;
    private periodCount = 0;


    @Type(() => Book)
    private book: Book;

    // @Exclude()
    @Type(() => Schedule)
    private schedule: Schedule;

    @Exclude()
    private timeline: Timeline;
    constructor(name: string, person: Profile, startDate: Date, initialAmount: number, periodCount: number) {
        this.name = name;
        this.person = person;
        this.startDate = startDate;
        this.initialAmount = initialAmount;
        this.periodCount = periodCount;
    }
    setValues(values: { name: string, person: Profile, startDate: Date, initialAmount: number, periodCount: number }) {
        this.name = values.name;
        this.person = values.person;
        this.startDate = values.startDate;
        this.initialAmount = values.initialAmount;
        this.periodCount = values.periodCount;
    }
    init() {
        this.schedule = new Schedule('Schedule', this.startDate.getFullYear(), this.person.birthDate, this.periodCount);
        this.book = new Book('Book', this.initialAmount, this.schedule);
        this.book.init();
    }
    getTimeline() {
        this.timeline = new Timeline(this.book, this.schedule);
        return this.timeline;
    }
    getBook() {
        return this.book;
    }
    getSchedule() {
        return this.schedule;
    }
    getInitialAmount(): number {
        return this.initialAmount;
    }
    getName(): string {
        return this.name;
    }
    getStartDate(): Date {
        return this.startDate;
    }
}
