import { Accumulation } from './accumulation.model'
import { Guid } from 'guid-typescript';
import { IInstance, IBook } from './instance.model';
import { Book } from './book.model';
import { Type, Exclude, Expose } from 'class-transformer';

export abstract class Flow extends IInstance {

    // protected uuid: string;
    // protected name: string;



    // @Type(() => Book)
    protected book: Book;
    // protected endPeriodNumber: number;
    // protected startPeriodNumber: number;
    // protected changeRate: number;
    // protected initialAmount: number;
    // protected amount: number;
    // public flowType: string;
    // public color: string;


    @Exclude()
    // @Type(() => Accumulation)
    protected source: Accumulation; // in 
    @Exclude()
    // @Type(() => Accumulation)
    protected target: Accumulation; // in 
    public __sourceIdValue: string;
    public __targetIdValue: string;
    constructor(name: string, book: IBook, initialAmount: number = 0, startPeriodNumber: number = 0,
        endPeriodNumber: number = 0, changeRate: number = 0, source: Accumulation = null, target: Accumulation = null) {
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate);

        this.category = 'Flow';
        this.source = source;
        this.target = target;

    }

    @Expose()
    get __sourceId() {
        return this.source != null ? this.source.getId() : null;
    }
    @Expose()
    get __targetId() {
        return this.target != null ? this.target.getId() : null;
    }
    set __sourceId(value: string) {
        this.__sourceIdValue = value;
    }
    set __targetId(value: string) {
        this.__targetIdValue = value;
    }
    setBook(book: Book) {
        this.book = book;
    }
    setTarget(instance: Accumulation) {
        this.target = instance;
    }
    setSource(instance: Accumulation) {
        this.source = instance;
    }
    getTarget() {
        return this.target;
    }
    getSource() {
        return this.source;
    }
    // Adding a method to the constructor
    toString() {
        return `account: ${this.name}, amount: $${this.amount}.`;
    }
    beforeProcess(previousState, currentPeriodNumber: number, retirementPeriod: number) {
        if (this.getAdjustedStartPeriodNumber(retirementPeriod) === currentPeriodNumber) {
            this.init();
        } else if (currentPeriodNumber >= this.getAdjustedStartPeriodNumber(retirementPeriod) && currentPeriodNumber <= this.getAdjustedEndPeriodNumber(retirementPeriod)) {
            this.amount = previousState.amount;
        } else {
            this.amount = 0; // not within the start end period
        }
    }

    /// process() and init() must return the same class
    process(currentPeriodNumber: number, retirementPeriod: number) {
        // Just grow it as a start

        if (currentPeriodNumber >= this.getAdjustedStartPeriodNumber(retirementPeriod) && currentPeriodNumber <= this.getAdjustedEndPeriodNumber(retirementPeriod)) {
            this.amount = this.amount * (1 + this.changeRate);
        } else {
            this.amount = 0; // not within the start end period
        }
        if (this.amount !== 0) {
            // skip if no impact
            this.processSpecific();
        }
    }
    /// process() and init() must return the same class
    init() {
        this.amount = this.initialAmount;
    }
    calculateAmount() {
        console.log('calculateAmount...');
    }
    getState() {
        return { amount: this.amount };
    }
    // abstract method
    processSpecific() {


        // get the minimum flowae amount
        if (this.getSource() != null) {
            const flowableAmount = this.getSource().allowableFlowFrom(this.amount, this);
            this.amount = flowableAmount; // set back the amount to flowable amount
        }
        if (this.getTarget() != null) {
            const flowableAmount = this.getTarget().allowableFlowTo(this.amount, this);
            if (flowableAmount < this.amount) {
                this.amount = flowableAmount; // set back the amount to flowable amount
            }
        }
        if (this.getSource() != null) {
            const flowableAmount = this.getSource().flowFrom(this.amount, this);
            this.amount = flowableAmount; // set back the amount to flowable amount
        }
        if (this.getTarget() != null) {
            this.getTarget().flowTo(this.amount, this);
        }
    }
    setActive(value: boolean) {
        if (value === false) {
            super.setActive(value);
        } else {
            if (this.canSetActive()) {
                super.setActive(value);
            }
        }
    }
    canSetActive(): boolean {
        const sourceStatus = this.getSource() ? this.getSource().isActive() : true;
        const targetStatus = this.getTarget() ? this.getTarget().isActive() : true;
        const canSetToActive = (sourceStatus && targetStatus);
        return canSetToActive;
    }
}
export abstract class InFlow extends Flow {
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0, source: Accumulation = null) {
        // Income has no source in this model, it flows from outside the model
        // it flows into Cash
        // super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, null, book.getCash());
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, source, null);

    }
    getTarget() {
        return this.book.getCash();
    }

}

export abstract class OutFlow extends Flow {
    // Expense has no target in this model, it flows to outside the model
    // it flows from Cash
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0, target: Accumulation = null) {
        // super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, book.getCash(), null);
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, null, target);
        this.flowType = 'Expense';
    }

    getSource() {
        return this.book.getCash();
    }
}
export class Income extends InFlow {
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0) {
        // Income has no source in this model, it flows from outside the model
        // it flows into Cash
        // super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, null, book.getCash());
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, null);
        this.flowType = 'Income';
    }

    getSource() {
        return null;
    }
}


export class Expense extends OutFlow {
    // Expense has no target in this model, it flows to outside the model
    // it flows from Cash
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0) {
        // super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, book.getCash(), null);
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, null);
        this.flowType = 'Expense';
    }
    getTarget() {
        return null;
    }

}

export class ItemizedExpenseBase extends Expense {
    protected readonly MONTHS = 12;

    expenseItems: any = [];
    // Expense has no target in this model, it flows to outside the model
    // it flows from Cash
    recurrentType: string;
    protected itemizedFlows: string[] = [];
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0) {
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate);
        this.flowType = 'Expense';
        this.recurrentType = 'Annually';
        this.expenseItems = [];
    }
    getItemizedFlows() {
        return this.itemizedFlows;
    }
    getStateSchema() {
        const schema = super.getStateSchema();

        schema['expenseItems'] = {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    type: { type: 'string', 'enum': this.itemizedFlows },
                    amount: { type: 'number', minimum: 0 }
                },
                'required': ['type', 'amount']
            }
        };
        delete schema['initialAmount'];

        schema['recurrentType'] = {
            type: 'string',
            title: 'Recurrent Type',
            required: true,
            oneOf: ['Annually', 'Monthly'].map(o => {
                return { 'title': o, 'const': o }
            })
        };
        return schema;
    }
    getStateValue() {
        const values = super.getStateValue();
        values['recurrentType'] = this.recurrentType;
        values['expenseItems'] = this.expenseItems;
        return values;
    }
    setStateValue(values) {
        super.setStateValue(values);
        if (values.recurrentType !== undefined) {
            this.recurrentType = values.recurrentType;
        }
        if (values.expenseItems) {
            this.expenseItems = values.expenseItems;
        } else {
            // if undefined, reset to []
            this.expenseItems = [];
        }
        this.initialAmount = this.calculateTotalAmount();
    }
    calculateTotalAmount() {
        let totalAmount = 0;

        // determine should we multiply the expenses by 12 months
        const multiplier = this.recurrentType === 'Annually' ? 1 : this.MONTHS;

        if (this.expenseItems) { // prevents null pointer
            totalAmount = this.expenseItems.reduce(function (a, b) {
                return a + b.amount
            }, 0) * multiplier; // to sum up items
        }
        return totalAmount;
    }
    init() {
        super.init();
        this.initialAmount = this.calculateTotalAmount();
        this.amount = this.initialAmount;
    }
}

export class ItemizedExpense0 extends Expense {
    protected readonly MONTHS = 12;
    expenseItems: any = [];
    // Expense has no target in this model, it flows to outside the model
    // it flows from Cash
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0) {
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate);
        this.flowType = 'Expense';
        this.expenseItems = [];
    }
    getStateSchema() {
        const schema = super.getStateSchema();

        schema['expenseItems'] = {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    type: { type: 'string', 'enum': ['Household', 'Food', 'Personal Care', 'Transport', 'Medical', 'Utilities', 'Charities', 'Communications', 'Entertainment', 'Education', 'Holidays', 'Rental', 'Tax', 'Children', 'Parent', 'Others'] },
                    amount: { type: 'number', minimum: 0 }
                },
                'required': ['type', 'amount']
            }
        };
        delete schema['initialAmount'];
        return schema;
    }
    getStateValue() {
        const values = super.getStateValue();

        values['expenseItems'] = this.expenseItems;
        return values;
    }
    setStateValue(values) {
        super.setStateValue(values);
        if (values.expenseItems) {
            this.expenseItems = values.expenseItems;
        } else {
            // if undefined, reset to []
            this.expenseItems = [];
        }
        this.initialAmount = this.calculateTotalAmount();
    }
    calculateTotalAmount() {
        let totalAmount = 0;
        if (this.expenseItems) { // prevents null pointer
            totalAmount = this.expenseItems.reduce(function (a, b) {
                return a + b.amount
            }, 0) * this.MONTHS; // to sum up items
        }
        return totalAmount;
    }
    init() {
        super.init();
        this.initialAmount = this.calculateTotalAmount();
        this.amount = this.initialAmount;
    }
}


export class ItemizedExpense extends ItemizedExpenseBase {
    itemizedFlows = ['Household', 'Food', 'Personal Care', 'Transport', 'Medical', 'Utilities', 'Charities', 'Communications', 'Entertainment', 'Education', 'Holidays', 'Rental', 'Tax', 'Children', 'Parent', 'Others'];
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0) {
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate);
    }
}

export class VehicleExpense extends ItemizedExpenseBase {
    itemizedFlows = ['Loan Repayment', 'Petrol', 'Maintenance', 'Parking', 'License', 'Tax', 'Others'];
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0) {
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate);
    }


}
export class Deposit extends OutFlow {
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0, target: Accumulation = null) {
        // super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, book.getCash(), target);
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, target);
        this.flowType = 'Deposit';
    }
    getStateSchema() {
        const schema = super.getStateSchema();

        schema['target'] = {
            type: 'string',
            title: 'Target',
            referenceType: 'Accumulation',
            referenceInstanceId: ''
        };
        return schema;
    }
    getStateValue() {
        const values = super.getStateValue();
        values['target'] = this.target; // ? this.target.uuid : '';
        return values;
    }
    setStateValue(values) {
        super.setStateValue(values);
        this.target = values.target;
    }
}
export class Withdrawal extends InFlow {
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0, source: Accumulation = null) {
        // super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, source, book.getCash());
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, source);
        this.flowType = 'Withdrawal';
    }
    getStateSchema() {
        const schema = super.getStateSchema();
        schema['source'] = {
            type: 'string',
            title: 'Source',
            referenceType: 'Accumulation',
        };
        return schema;
    }
    getStateValue() {
        const values = super.getStateValue();
        values['source'] = this.source; // ? this.source.uuid : '';
        return values;
    }
    setStateValue(values) {
        super.setStateValue(values);
        this.source = values.source;
    }
}

export class Repayment extends Deposit {
    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0, target: Accumulation = null) {
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, target);
    }
}
export class Borrowing extends Withdrawal {

    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, changeRate: number = 0, source: Accumulation = null) {
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, changeRate, source);
    }
}
export class Transfer extends Flow {

    constructor(name: string = null, book: IBook = null, initialAmount: number = 0, startPeriodNumber: number = 0, endPeriodNumber: number = 0, source: Accumulation = null, target: Accumulation = null) {
        super(name, book, initialAmount, startPeriodNumber, endPeriodNumber, 0, source, target);
    }
 
    getStateSchema() {
        const schema = super.getStateSchema();
        schema['source'] = {
            type: 'string',
            title: 'Source',
            referenceType: 'Accumulation',
        };
        schema['target'] = {
            type: 'string',
            title: 'Target',
            referenceType: 'Accumulation',
        };
        return schema;
    }
    getStateValue() {
        const values = super.getStateValue();
        values['source'] = this.source; // ? this.source.uuid : '';
        values['target'] = this.target; // ? this.target.uuid : '';

        return values;
    }
    setStateValue(values) {
        super.setStateValue(values);
        this.source = values.source;
        this.target = values.target;
    }
}
