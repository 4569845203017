import { Withdrawal } from './flows.model'
import { Book } from './book.model'
import { Schedule, Period } from './schedule.model'
declare const Queue: any;

export class Timeline {
    private book: Book;
    private schedule: Schedule;
    private currentPeriodNumber: number;
    private startPeriodNumber: number;
    private flowQueue: any;
    public states: Map<number, any>;
    private periods: Array<Period>;
    private retirementPeriod: number;
    constructor(book: Book, schedule: Schedule) {
        this.book = book;
        this.currentPeriodNumber = 0;
        this.schedule = schedule;
        this.startPeriodNumber = this.schedule.startYear;

        this.states = new Map();
        this.flowQueue = new Queue();

        this.periods = [];
        // for (let i = 0; i < this.schedule.periodCount; i++) {
        //     const date = new Date(this.schedule.startDate.getFullYear() + i, 0, 1);
        //     this.periods.push(new Period(`${date.getFullYear()}`, date, date.getFullYear(), date.getFullYear() - schedule.birthDate.getFullYear()));
        // }
        this.periods.push(...this.schedule.getPeriods()); // copy periods from schedule 
    }
    getPeriods() {
        return this.periods;
    }
    beforeProcess(previousState, currentPeriodNumber: number) {
        this.flowQueue = new Queue(); // reset the flow processing queue

        this.book.getActiveFlows().forEach(flow => {
            this.flowQueue.enqueue(flow); // enqueue each pre-defined flows to the flow processing queue
            flow.beforeProcess(previousState ? previousState.get(flow) : null, currentPeriodNumber, this.retirementPeriod);
        });
        this.book.getActiveAssets().forEach(asset => {
            asset.beforeProcess(previousState ? previousState.get(asset) : null, currentPeriodNumber, this.retirementPeriod);
        });
        this.book.getActiveLiabilities().forEach(liability => {
            liability.beforeProcess(previousState ? previousState.get(liability) : null, currentPeriodNumber, this.retirementPeriod);
        });
        this.book.getCash().beforeProcess(previousState ? previousState.get(this.book.getCash()) : null, currentPeriodNumber, this.retirementPeriod);

    }
    afterProcess(currentState) {
        this.book.getActiveFlows().forEach(flow => {
            currentState.set(flow, flow.getState());
        });
        this.book.getActiveAssets().forEach(asset => {
            currentState.set(asset, asset.getState());
        });
        this.book.getActiveLiabilities().forEach(liability => {
            currentState.set(liability, liability.getState());
        });

        currentState.set(this.book.getCash(), this.book.getCash().getState());
    }
    processFlows(currentPeriodNumber: number, currentState) {
        while (!this.flowQueue.isEmpty()) {
            const flow = this.flowQueue.dequeue();
            flow.process(currentPeriodNumber, this.retirementPeriod);
        }


    }
    process(currentPeriodNumber: number, currentState) {
        this.book.getActiveAssets().forEach(asset => {
            if (!currentState.has(asset)) {
                asset.process(currentPeriodNumber, this.retirementPeriod);
                //currentState.set(asset, newState);
            } else {
                //console.log('asset: ' + asset.name + ' has been processed for ' + currentPeriod.name)
            }
        });
        this.book.getActiveLiabilities().forEach(liability => {
            if (!currentState.has(liability)) {
                liability.process(currentPeriodNumber, this.retirementPeriod);
                //currentState.set(liability, newState);
            } else {
                // console.log('liability: ' + liability.name + ' has been processed for ' + currentPeriod.name)
            }
        });
        this.book.getCash().process(currentPeriodNumber, this.retirementPeriod);

    }
    /// Run this before starting the forward()
    init(retirementPeriod: number) {

        this.retirementPeriod = retirementPeriod;
        this.states = new Map();
        this.currentPeriodNumber = this.startPeriodNumber;

        //this.book.schedule.init();
    }

    forward() {

        const previousPeriodNumber = this.currentPeriodNumber - 1;
        const previousState = this.states.get(previousPeriodNumber);

        this.beforeProcess(previousState, this.currentPeriodNumber);


        const currentState = new Map()
        this.states.set(this.currentPeriodNumber, currentState);

        this.processFlows(this.currentPeriodNumber, currentState);
        this.process(this.currentPeriodNumber, currentState);


        if (this.book.getCash().getAmount() < 0) {
            const autoWithdrawalables = Array.from(this.book.getAccumulations()).filter(accumulation => accumulation.isActive() && accumulation.autoWithdrawalPriority > 0);
            autoWithdrawalables.sort((a, b) => Number(b.autoWithdrawalPriority) - Number(a.autoWithdrawalPriority)); // compare Descending

            let i = 0;
            while (i < autoWithdrawalables.length) {
                const autoWithdrawalabeSource = autoWithdrawalables[i];
                this.flowQueue.enqueue(new Withdrawal('* Auto Withdrawal from ' + autoWithdrawalabeSource.getName(),
                    this.book, -this.book.getCash().getAmount(), this.currentPeriodNumber, this.currentPeriodNumber, 0, autoWithdrawalabeSource));
                // add to the processing queue instead of the main flows collection
                this.processFlows(this.currentPeriodNumber, currentState);
                i++;
            }
        }
        this.processFlows(this.currentPeriodNumber, currentState);

        this.afterProcess(currentState);

        this.currentPeriodNumber++;

        return currentState;
    }
}
