
import { Asset, Cash, Savings, Investment, Insurance, ProvidentFund, Annuity, Property } from './assets.model'
import { Liability, FlatRateLoan, MonthlyRestLoan } from './liabilities.model'
import { Flow, Income, Expense, ItemizedExpense, Deposit, Repayment, Borrowing, Transfer, Withdrawal, VehicleExpense } from './flows.model'
import { Schedule } from './schedule.model'
import { IBook, IInstance } from './instance.model';
import { Accumulation } from './accumulation.model';
import { Exclude, Type } from 'class-transformer';
import { assertNotNull } from '@angular/compiler/src/output/output_ast';


export class Book implements IBook {
  clearAll() {
    this.assets = [];
    this.liabilities = [];
    this.flows = [];
  }
   
    private name: string;

    @Type(() => Asset, {
        discriminator: {
            property: '__type',
            subTypes: [
                { value: Savings, name: 'Savings' },
                { value: Investment, name: 'Investment' },
                { value: Insurance, name: 'Insurance' },
                { value: ProvidentFund, name: 'ProvidentFund' },
                { value: Annuity, name: 'Annuity' },
                { value: Property, name: 'Property' },
                { value: Asset, name: 'Asset' }
            ]
        }
    })
    private assets: Array<Asset>;

    @Type(() => Liability, {
        discriminator: {
            property: '__type',
            subTypes: [
                { value: FlatRateLoan, name: 'FlatRateLoan' },
                { value: MonthlyRestLoan, name: 'MonthlyRestLoan' },
                { value: Liability, name: 'Liability' }
            ]
        }
    })
    private liabilities: Array<Liability>;

    @Type(() => Flow, {
        discriminator: {
            property: '__type',
            subTypes: [
                { value: Income, name: 'Income' },
                { value: Expense, name: 'Expense' },
                { value: ItemizedExpense, name: 'ItemizedExpense' },
                { value: VehicleExpense, name: 'Vehicle Expense'},                
                { value: Deposit, name: 'Deposit' },
                { value: Withdrawal, name: 'Withdrawal' },
                { value: Repayment, name: 'Repayment' },
                { value: Borrowing, name: 'Borrowing' },
                { value: Transfer, name: 'Transfer' }
            ]
        }
    })
    private flows: Array<Flow>;

    @Type(() => Cash)
    private cash: Cash;

    @Exclude()
    protected schedule: Schedule;

    // private activeAssets = new Set<Asset>();
    // private activeLiabilities = new Set<Liability>();
    // private activeFlows = new Set<Flow>();

    public colors = ['#FF6E7A', '#AE4F64', '#8B28EB', '#061BAB', '#DBA1E3', '#B9EFFF', '#F47283',
        '#B94910', '#48D96A', '#007F84', '#F4C681', '#9500FF', '#137001', '#7696F0', '#A4ED99', '#B9C21B',
        '#E9DB5F', '#45DCE0', '#ED809B', '#95FFEE', '#A7610A', '#04F4A4', '#1863AB', '#67F2B5', '#CDD641',
        '#63FF22', '#3FE62C', '#78EEF4', '#13B9B0', '#29EDEA', '#E679D2', '#F488A3', '#8B99EB', '#81F9D6', '#A0FFBD', '#E66AF1', '#1AEB98'];
    @Exclude()
    public colorMap: Map<string, string>;
    @Exclude()
    private accumulationColorPool = [];
    @Exclude()
    private flowColorPool = [];

    private initialCashAmount = 0;
    constructor(name: string, initialCashAmount: number, schedule: Schedule) {
        this.name = name;
        this.initialCashAmount = initialCashAmount;
        this.schedule = schedule;


        this.resetColor();
    }
    setValues(values: { name: string, initialCashAmount: number, schedule: Schedule }) {
        this.name = values.name;
        this.initialCashAmount = values.initialCashAmount;
        this.schedule = values.schedule;
        // this.assets = new Set<Asset>();
        // this.liabilities = new Set<Liability>();
        // this.flows = new Set<Flow>();

    }
    init() {
        this.cash = new Cash('Cash', this, this.initialCashAmount, this.schedule.getYear(0));
        this.assets = new Array<Asset>();
        this.liabilities = new Array<Liability>();
        this.flows = new Array<Flow>();
        this.resetColor();
    }
    private checkNameDuplicate(collection, instance: IInstance) {
        const names = Array.from(this.assets).map(o => o.getName());
        if (names.includes(instance.getName())) {
            throw new Error('Duplicate name already in collection: ' + instance.getName());
        }
    }
    addAsset(asset: Asset) {
        this.checkNameDuplicate(this.assets, asset);
        this.assets.push(asset);
        this.assignColor(asset);
    }
    addLiability(liability: Liability) {
        this.checkNameDuplicate(this.liabilities, liability);
        this.liabilities.push(liability);
        this.assignColor(liability);
    }
    getAccumulations(): Set<Accumulation> {
        return new Set([...this.assets, ...this.liabilities])
    }
    getInstances() {
        return new Set([...this.assets, ...this.liabilities, ...this.flows])
    }
    addFlow(flow) {
        this.checkNameDuplicate(this.flows, flow);
        this.flows.push(flow);
        this.assignColor(flow);
    }
    removeFlow(flow) {
        // this.flows.delete(flow);

        this.removeInstance(this.flows, flow);
        this.flowColorPool.push(flow.getColor()); // recycle the color

    }


    removeInstance(collection, instance) {
        const index = collection.indexOf(instance);

        if (index > -1) {
            collection.splice(index, 1);
        }
    }
    removeAccumulation(instance) {
        // this.assets.delete(instance);
        const dependentFlows = this.getDependFlows(instance);
        this.removeInstance(this.assets, instance);
        // this.liabilities.delete(instance);

        this.removeInstance(this.liabilities, instance);
        this.accumulationColorPool.push(instance.getColor()); // recycle the color

        dependentFlows.forEach(flow => {
            this.removeFlow(flow);
        })
    }
    getLiabilities() {
        return this.liabilities;
    }
    getAssets() {
        return this.assets;
    }
    getFlows() {
        return this.flows;
    }
    getInstanceByName(name: string): IInstance {
        const instances = [...this.assets, ...this.liabilities, ...this.flows];
        return instances.filter(o => o.getName() === name)[0];
    }
    getInstanceById(id: string): IInstance {
        const instances = [...this.assets, ...this.liabilities, ...this.flows];
        const filteredInstances = Array.from(instances).filter(o => o.getId() === id);
        if (filteredInstances.length !== 1) {
            // console.log('Cannot find single instance with id: ' + id);
            throw new Error('Cannot find single instance with id: ' + id);
            return;
        }
        const instance = filteredInstances[0];

        return instance;
        return instances.filter(o => o.getId() === id)[0];
    }
    getAccumulationById(id: string): Accumulation {
        const instances = [...this.assets, ...this.liabilities];
        const filteredInstances = instances.filter(o => o.getId() === id);
        if (filteredInstances.length < 1) {
            throw new Error('Cannot find instance Id: ' + id);
        }
        return filteredInstances[0];
    }
    getFlowById(id: string): Flow {
        const instances = [...this.flows];
        const filteredInstances = instances.filter(o => o.getId() === id);
        if (filteredInstances.length < 1) {
            throw new Error('Cannot find instance Id: ' + id);
        }
        return filteredInstances[0];
    }
    getActiveLiabilities() {
        return new Set(Array.from(this.liabilities).filter(o => o.isActive()));
    }
    getActiveAssets() {
        return new Set(Array.from(this.assets).filter(o => o.isActive()));
    }
    getActiveFlows() {
        return new Set(Array.from(this.flows).filter(o => o.isActive()));
    }
    // Calculates active status of each flow based on the target and source's status
    calculateFlowsActiveStatus() {
        this.flows.forEach(flow => {
            const sourceStatus = flow.getSource() ? flow.getSource().isActive() : true;
            const targetStatus = flow.getTarget() ? flow.getTarget().isActive() : true;

            flow.setActive(sourceStatus && targetStatus); // set the flow active status if both source and target are active 
        });
    }
    setActive(instance, proposedActiveStatus) {
        if (this.getAccumulations().has(instance)) {
            const dependentFlows = this.getDependFlows(instance);
            instance.setActive(proposedActiveStatus);
            dependentFlows.forEach(flow => {
                flow.setActive(proposedActiveStatus);
            });
        } else if (this.getFlows().includes(instance)) {
            instance.setActive(proposedActiveStatus);
        } else {
            throw new Error('Instance not found in Book');
        }
    }
    getDependFlows(accumulation: Accumulation): Array<Flow> {
        if (!this.getAccumulations().has(accumulation)) {
            throw new Error('Instance not inside Accumulations set');
        }
        const dependentFlows = this.getFlows().filter((flow) => {
            return flow.getTarget() === accumulation || flow.getSource() === accumulation;
        })
        return dependentFlows;
    }
    // setActiveLiabilities(value) {
    //     const selectedIds = value.map(o => o.id);
    //     this.activeLiabilities = new Set(Array.from(this.liabilities).filter(o => selectedIds.includes(o.getId())));

    // }
    // setActiveAssets(value) {
    //     const selectedIds = value.map(o => o.id);
    //     this.activeAssets = new Set(Array.from(this.assets).filter(o => selectedIds.includes(o.getId())));

    // }
    // setActiveFlows(value) {
    //     const selectedIds = value.map(o => o.id);
    //     this.activeFlows = new Set(Array.from(this.flows).filter(o => selectedIds.includes(o.getId())));
    // }
    getCash() {
        return this.cash;
    }
    // getSchedule() {
    //     return this.schedule;
    // }
    // Adding a method to the constructor
    toString() {
        return `Book: ${this.name}`;
    }


    /* Persistence **************** */
    getStateValue() {
        const assets = Array.from(this.assets).map(item => item.getStateValue());
        const liabilities = Array.from(this.liabilities).map(item => item.getStateValue());
        const flows = Array.from(this.flows).map(item => item.getStateValue());
        return {
            name: this.name,
            //    schedule: this.schedule.getStateValue(),
            assets: assets,
            liabilities: liabilities,
            cash: this.cash.getStateValue(),
            flows: flows
        };
    }
    setStateValue(values) {
        /*
                this.name = values.name;



                const cash = new Cash('Cash', this);
                cash.setStateValue(values.cash);
                this.cash = cash;

                const assets = new Set<Asset>();
                values.assets.forEach(value => {
                    // const _class = value._class;
                    // const item = repository.createInstance(_class, this);
                    // var item = new Liability(null, this);
                    item.setStateValue(value);
                    assets.add(item);
                });
                this.assets = assets;

                const liabilities = new Set<Liability>();
                values.liabilities.forEach(value => {
                    const _class = value._class;
                    const item = repository.createInstance(_class, this);
                    // var item = new Asset(null, this);
                    item.setStateValue(value);
                    liabilities.add(item);
                });
                this.liabilities = liabilities;

                const flows = new Set<Flow>();
                values.flows.forEach(value => {
                    const _class = value._class;
                    if (value['target']) {
                        // get and replace uuid with actual instance
                        const uuid = value['target'];
                        const instances = Array.from(this.getAccumulations()).filter(accumulation => accumulation.uuid === uuid);
                        value['target'] = instances[0];
                    }
                    if (value['source']) {
                        // get and replace uuid with actual instance
                        const uuid = value['source'];
                        const instances = Array.from(this.getAccumulations()).filter(accumulation => accumulation.uuid === uuid);
                        value['source'] = instances[0];
                    }


                    const item = repository.createInstance(_class, this);
                    // var item = new Flow(null, this);
                    item.setStateValue(value);



                    flows.add(item);
                });






                this.flows = flows;
                */
    }

    resetColor() {
        this.colorMap = new Map<string, string>();

        this.flowColorPool.push(...this.colors);

        this.accumulationColorPool.push(...this.colors);
        this.accumulationColorPool.reverse(); // reverse the colors so that it is not too similiar to flow colors
    }
    getNameColorMap() {
        const colorNameMap = {};
        // this.getAccumulations().forEach(item => {
        //     colorNameMap[item.getName()] = this.colorMap[item.getId()];
        // });
        // this.getFlows().forEach(item => {
        //     colorNameMap[item.getName()] = this.colorMap[item.getId()];
        // });
        this.getInstances().forEach(item => {
            colorNameMap[item.getName()] = item.getColor();
        });

        colorNameMap['Cash'] = '#356580';
        colorNameMap['Net Worth'] = '#30CECC';
        colorNameMap['Net Flow'] = '#B2F472';
        return colorNameMap;
    }
    assignColor(instance: IInstance) {
        let colorPool;
        let colorMap;
        const key = instance.getId();
        // const category = instance.getCategory();
        if (instance instanceof Flow) {
            colorPool = this.flowColorPool;
            colorMap = this.colorMap;
        } else if (instance instanceof Accumulation) {
            colorPool = this.accumulationColorPool;
            colorMap = this.colorMap;
        } else {
            throw new Error('Error, cannot handle this instance: ' + instance);
        }
        // switch (category) {
        //     case 'Flow':
        //         colorPool = this.flowColorPool;
        //         colorMap = this.colorMap;
        //         break;
        //     case 'Asset':
        //     case 'Liability':
        //         colorPool = this.accumulationColorPool;
        //         colorMap = this.colorMap;
        //         break;
        //     default: throw new Error('Error, no such category: ' + category);
        // }
        let color = colorMap[key];

        if (color == null) {
            color = colorPool.pop();
            colorMap[key] = color;
        }
        instance.setColor(color);

    }


    addInstance(instance: IInstance) {
        if (instance instanceof Flow) {
            this.addFlow(instance);
        } else if (instance instanceof Asset) {
            this.addAsset(instance);
        } else if (instance instanceof Liability) {
            this.addLiability(instance);
        } else {
            throw new Error('Error, cannot handle this instance: ' + instance);
        }

        // const editingInstanceClass = instance.getCategory();
        // switch (editingInstanceClass) {
        //     case 'Flow':
        //         this.addFlow(instance);
        //         break;
        //     case 'Asset':
        //         this.addAsset(instance);
        //         break;
        //     case 'Liability':
        //         this.addLiability(instance);
        //         break;
        //     default: throw new Error('Error: ' + editingInstanceClass);
        // }


        return instance;
    }

    getStateSchema() {
        const schema = {

            name: {
                type: 'string',
                title: 'Name',
                required: true,
                minLength: 3,
                maxLength: 50
            },
            cash: {
                _class: 'Cash',

            },
            assets: {

            }


        };
        const assets = Array.from(this.assets).map(item => item.getStateValue());
        const liabilities = Array.from(this.liabilities).map(item => item.getStateValue());
        const flows = Array.from(this.flows).map(item => item.getStateValue());
        return {
            name: this.name,
            //    schedule: this.schedule.getStateValue(),
            assets: assets,
            liabilities: liabilities,
            cash: this.cash.getStateValue(),
            flows: flows
        };
    }

}
