import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from 'app/sidebar/sidebar.component';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressBarModule, ProgressBar } from 'primeng/progressbar';

import { Routes, RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        SidebarModule,
        ProgressBarModule,
        RouterModule
        
    ],
    declarations: [
        SidebarComponent
    ],
    exports: [
        SidebarComponent,
        ProgressBar
    ]
})
export class SharedModule { }
