
export class Profile {
    private _name: string;
    private _gender: string;
    private _birthDate: Date;
    private _retirementAge: number;


    constructor(name: string, birthDate: Date) {
        this.name = name;
        this.birthDate = birthDate;
    }
    get name() {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }
    get birthDate() {
        return this._birthDate;
    }

    set birthDate(value: Date) {
        this._birthDate = value;
    }
    get gender() {
        return this._gender;
    }
    set gender(value: string) {
        if (value !== 'male' && value !== 'female') {
            throw new Error('Allowed values is "male" or "female"');
        }
        this._gender = value;
    }
    get retirementAge() {
        return this._retirementAge;
    }

    set retirementAge(value: number) {
        this._retirementAge = value;
    }
    // newPlan(name: string, startDate: Date, initialAmount: number, periodCount: number) {
    //     const plan = new Plan(name, this, startDate, initialAmount, periodCount);
    //     return plan;
    // }
}
