import { Component, HostBinding } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'not-found-cmp',
    templateUrl: 'not-found.component.html'
})

export class NotFoundComponent {

}
