
import * as flow from './flows.model'
import * as asset from './assets.model'
import * as liability from './liabilities.model'
import { Book } from './book.model'
import { IInstance, PersistableInstance } from './instance.model';
import { PlannerService } from '../../core/planner/planner-service';
import { Period } from './schedule.model';
import { Plan } from './planner.model';

export class Repository {

}
interface ScheduleOption {
    title: string,
    const: number
}
export class InstanceProxy implements PersistableInstance {
    PROXY_ATTRIBUTE_SUFFIX = '____Ref';
    plan: Plan;
    constructor(private instance: PersistableInstance, plan: Plan) {
        this.plan = plan;
    }
    beforeProcess(previousState: any, currentPeriodNumber: number): void {
        throw new Error('Method not implemented.');
    }
    process(currentPeriodNumber: number): void {
        throw new Error('Method not implemented.');
    }
    init(): void {
        throw new Error('Method not implemented.');
    }

    private getInstance(id: string): IInstance {
        const instances = this.plan.getBook().getInstances();
        const filteredInstances = Array.from(instances).filter(o => o.getId() === id);
        if (filteredInstances.length !== 1) {
            console.log('Cannot find single instance with id: ' + id);
            return;
        }
        const filteredInstance = filteredInstances[0];



        return filteredInstance;
    }
    private getInstanceByName(name: string): IInstance {
        const instances = this.plan.getBook().getInstances();
        const filteredInstances = Array.from(instances).filter(o => o.getName() === name);
        if (filteredInstances.length !== 1) {
            console.log('Cannot find single instance with id: ' + name);
            return;
        }
        const filteredInstance = filteredInstances[0];



        return filteredInstance;
    }
    getOriginalInstance() {
        return this.instance;
    }
    getStateSchema() {
        const schema = this.instance.getStateSchema();
        const attibuteKeys = Object.keys(schema);

        const attributesToBeRemoved = [];
        attibuteKeys.forEach(attibuteKey => {
            const attibute = schema[attibuteKey];
            if (attibute.referenceType === 'Accumulation') {
                // const allowedReferences = Array.from(this.plan.getBook().getAccumulations()).map(o => o.getName());
                const allowedReferences = Array.from(this.plan.getBook().getAccumulations()).map(function (o) {
                    return { 'title': o.getName(), 'const': o.getId() };
                });

                // this attibute is a reference type, we will create a standin for it
                schema[attibuteKey + '____Ref'] = {
                    type: attibute.type,
                    title: attibute.title,
                    // enum: allowedReferences
                    oneOf: allowedReferences
                }
                attributesToBeRemoved.push(attibuteKey);
            }

            // https://hamidihamza.com/Angular6-json-schema-form/?set=ng-jsf&example=ng-jsf-select-list-examples&framework=bootstrap-3&language=en
            if (attibute.referenceType === 'Schedule') {
                const schedule = this.plan.getSchedule();
                const allowedReferences = schedule.getPeriods().map(function (o) {
                    return { 'title': o.toString(), 'const': o.year };
                });
                const retirementAgePeriod: ScheduleOption = {
                    title: 'Retirement Age',
                    const: 0 
                };
                 allowedReferences.unshift({ 'title': 'Retirement Age', 'const': 10000 });

                // for (let year = schedule.getYear(0); year < schedule.getEndYear(); year++) {
                //     allowedReferences.push(year);
                // }

                // "oneOf":  [                    { "title": "James T. Kirk",    "const": "William Shatner" }]
                // schema[attibuteKey].enum = allowedReferences;
                schema[attibuteKey].oneOf = allowedReferences;
                // schema[attibuteKey].minimum = this.plan.getSchedule().getYear(0);
                // schema[attibuteKey].maximum = this.plan.getSchedule().getEndYear();
            }
        });

        // remove the original referencing attribute
        while (attributesToBeRemoved.length > 0) {
            delete schema[attributesToBeRemoved.pop()];
        }
        return schema;
    }

    getStateValue() {
        const value = this.instance.getStateValue();

        const schema = this.instance.getStateSchema();

        const attibuteKeys = Object.keys(schema);

        // convert the reference attributes's referenced id to the selected item's name
        attibuteKeys.forEach(attibuteKey => {
            const attibute = schema[attibuteKey];
            if (attibute.referenceType === 'Accumulation') {
                let reference = null;

                const instance = value[attibuteKey];

                if (instance) {
                    const id = instance.uuid;

                    if (id !== null && id !== '') {
                        const filteredInstance = this.getInstance(id);

                        // enum cannot use complex types, so we have convert the uuid to using name!!
                        // reference = filteredInstance.getName();

                        reference = filteredInstance.getId();
                    }
                }
                value[attibuteKey + '____Ref'] = reference;

            }

        });



        return value;
    }
    setStateValue(values) {
        const schema = this.instance.getStateSchema();

        const attibuteKeys = Object.keys(schema);

        const attributesToBeRemoved = [];
        attibuteKeys.forEach(attibuteKey => {
            const attibute = schema[attibuteKey];
            if (attibute.referenceType === 'Accumulation') {

                // get the value for this reference attribute from the form
                const proxyAttributeKey = this.getProxyAttributeName(attibuteKey);
                const id = values[proxyAttributeKey];

                // set the value of the orginal attribute to the id of the object
                values[attibuteKey] = this.getInstance(id);

                attributesToBeRemoved.push(proxyAttributeKey);
            }
            if (attibute.referenceType === 'Schedule') {


            }

        });

        // remove the original referencing attribute
        while (attributesToBeRemoved.length > 0) {
            delete values[attributesToBeRemoved.pop()];
        }
        this.instance.setStateValue(values);
    }

    getProxyAttributeName(attibuteKey) {
        return attibuteKey + this.PROXY_ATTRIBUTE_SUFFIX;
    }
    getUnproxyAttributeName(attibuteKey) {
        return attibuteKey.replace(this.PROXY_ATTRIBUTE_SUFFIX, '');
    }
}
