import { Component, OnInit, OnDestroy, HostBinding, AfterViewInit } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { SideBarService } from './sidebar.service';
import { Subscription } from 'rxjs';

declare var $: any;

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    auth: boolean;
}

export const ROUTES: RouteInfo[] = [

    { path: '/home', title: 'Home', icon: 'ti-home', class: '', auth: false },
    { path: '/plan/dashboard', title: 'Dashboard', icon: 'ti-panel', class: '', auth: false },
    { path: '/details/profile', title: 'Profile', icon: 'ti-user', class: '', auth: true },
    { path: '/details/start', title: 'Start', icon: 'ti-view-list-alt', class: '', auth: false }
    // { path: 'user', title: 'User Profile',  icon:'ti-user', class: '' },
    // { path: '/sample/table', title: 'Table List', icon: 'ti-view-list-alt', class: '' },
    // { path: '/sample/typography', title: 'Typography', icon: 'ti-text', class: '' },
    // { path: '/sample/icons', title: 'Icons', icon: 'ti-pencil-alt2', class: '' },
    // { path: 'maps', title: 'Maps',  icon:'ti-map', class: '' },
    // { path: '/sample/notifications', title: 'Notifications', icon: 'ti-bell', class: '' },
    // { path: '/sample/ngx-bootstrap', title: 'ngx-bootstrap', icon: 'ti-user', class: '' }


];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    // styleUrls: ['sidebar.component.css']
})

export class SidebarComponent implements OnInit, OnDestroy {

    @HostBinding('class.is-open')
    isOpen = false;

    public menuItems: any[];
    subscription: Subscription;
    constructor(private router: Router, private authService: AuthService, private sideBarService: SideBarService) {
        // https://stackoverflow.com/questions/43882389/angular-2-side-bar-to-be-closed-whenever-a-route-is-changed
        router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.isOpen = false;
            }
        });
    }
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.subscription = this.sideBarService.change.subscribe(() => {
            this.isOpen = !this.isOpen;
        });

    }

    
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    isNotMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    signInWithGoogle() {
        this.authService.signInWithGoogle()
            .then((res) => {
                this.router.navigate(['/plan/dashboard'])
            })
            .catch((err) => console.log(err));
    }
}
