import { Injectable, ɵPlayerHandler } from '@angular/core';

import { Profile } from '../../shared/models/profile.model'
import { Plan } from '../../shared/models/planner.model'
import { Book } from '../../shared/models/book.model'
import { Timeline } from '../../shared/models/timeline.model'
import { Guid } from 'guid-typescript';
import * as flow from '../../shared/models/flows.model'
import * as asset from '../../shared/models/assets.model'
import * as liability from '../../shared/models/liabilities.model'
import { Period } from '../../shared/models/schedule.model';
import { InstanceProxy } from '../../shared/models/repository.model';
import { IInstance, IBook } from '../../shared/models/instance.model';
import { DataService } from 'app/core/data.service';
import { AuthService } from 'app/core/auth.service';
import * as _ from 'lodash';
import { classToPlain } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class PlannerService {
  private plan: Plan = null;
  private profile: Profile = null;

  private clonedPlan: Object;
  constructor(private dataService: DataService, private authService: AuthService) {

  }
  createPlan(name: string, startDate: Date, initialAmount: number, periodCount: number) {
    const plan = new Plan(name, this.profile, startDate, initialAmount, periodCount);
    plan.setValues({ name: name, person: this.profile, startDate: startDate, initialAmount: initialAmount, periodCount: periodCount });
    plan.init();
    return plan;
  }
  // async loadPlan() {
  //   if (this.authService.isLoggedIn()) {
  //     const user = this.authService.getCurrentUserDetail();

  //     const plan = await this.dataService.getPlan(user.uid);
  //     this.setPlan(plan);
  //   }
  // }
  isDirty() {

    const currentPlan = classToPlain(this.plan);
    return !_.isEqual(currentPlan, this.clonedPlan);

  }
  saveProfile(userId) {
    return new Promise(
      (resolve, reject) => {
        this.dataService.createProfileWithUid(userId, this.profile)
          .then(() => {
            resolve(true);
          });
      }
    )
  }
  async isProfileExist(userId: string): Promise<boolean> {
    return new Promise(
      (resolve, reject) => {
        this.dataService.getProfile(userId).subscribe(
          data => {
            console.log(data);
            const payload = data.payload;
            resolve(payload.exists)
          },
          error => {
            console.log(error);
            reject(error);
          },
          () => {
            // completed
          }
        );
      }
    );
    // .then(
    //   data => {
    //     console.log(data);
    //     const payload = data.payload;
    //     // return (payload.exists);

    //     return (payload.exists);
    //   }
    // );
    // .catch((error) => {
    //   Promise.reject(error);
    // });
    // return exists;
  }
  async savePlan() {
    return new Promise(
      (resolve, reject) => {
        if (this.authService.isLoggedIn()) {
          const user = this.authService.getCurrentUserDetail();
          console.log(user);
          this.dataService.getProfile(user.uid).subscribe(
            data => {
              console.log(data);
              const payload = data.payload;
              if (!payload.exists) {
                this.saveProfile(user.uid).then(() => {
                  this.dataService.insertPlanWithId(user.uid, this.getPlan()).then(() => {
                    this.setPlan(this.plan);
                    resolve(true);
                  }).catch((error) => {
                    reject(error);
                  });
                });
              } else {
                this.dataService.insertPlanWithId(user.uid, this.getPlan()).then(() => {
                  this.setPlan(this.plan);
                  resolve(true);
                }).catch((error) => {
                  reject(error);
                });
              }
            });
        } else {
          reject('Not signed in');
        }
      }
    );
  }
  async loadPlan() {
    return new Promise(
      (resolve, reject) => {
        if (this.authService.isLoggedIn()) {
          const user = this.authService.getCurrentUserDetail();
          console.log(user);

          this.dataService.getPlanWithId(user.uid)
            .then((plan) => {
              console.log(plan);

              // set the source and target, and book
              plan.getBook().getFlows().forEach(instance => {
                instance.setBook(plan.getBook());
                if (instance.__sourceIdValue != null) {
                  const referencedInstance = plan.getBook().getAccumulationById(instance.__sourceIdValue);
                  instance.setSource(referencedInstance);
                }
                if (instance.__targetIdValue != null) {
                  const referencedInstance = plan.getBook().getAccumulationById(instance.__targetIdValue);
                  instance.setTarget(referencedInstance);
                }
              });
              this.setPlan(plan);
              resolve(true);
            }).catch((error) => {
              reject(error);
            });
        } else {
          reject('Not signed in');
        }
      }

    );
  }
  // async loadPlan0() {
  //   if (this.authService.isLoggedIn()) {
  //     const user = this.authService.getCurrentUserDetail();
  //     console.log(user);

  //     this.dataService.getPlan(user.uid)
  //       .then((plan) => {
  //         console.log(plan);

  //         plan.getBook().getFlows().forEach(instance => {
  //           if (instance.__sourceIdValue != null) {
  //             const referencedInstance = plan.getBook().getAccumulationById(instance.__sourceIdValue);
  //             instance.setSource(referencedInstance);
  //           }
  //           if (instance.__targetIdValue != null) {
  //             const referencedInstance = plan.getBook().getAccumulationById(instance.__targetIdValue);
  //             instance.setTarget(referencedInstance);
  //           }
  //         });
  //         this.setPlan(plan);
  //         return true;
  //       }).catch((error) => {
  //         throw new Error(error);
  //       });
  //   }


  // }
  setPlan(plan: Plan) {
    this.plan = plan;

    // this.clonedPlan = _.cloneDeep(plan);
    this.clonedPlan = classToPlain(this.plan);
    // this._isDirty = false;
  }

  getProfile(): Profile {
    return this.profile;
  }
  setProfile(profile: Profile) {
    this.profile = profile;
  }
  getInstanceProxy(instance: IInstance, plan: Plan): InstanceProxy {
    return new InstanceProxy(instance, plan);
  }
  createInstance(editingInstanceClass: string, book: Book) {
    let instance = null;
    switch (editingInstanceClass) {
      case 'Income': instance = new flow.Income(null, book);
        break;
      case 'Expense': instance = new flow.Expense(null, book);
        break;
      case 'ItemizedExpense': instance = new flow.ItemizedExpense(null, book);
        break;
      case 'VehicleExpense': instance = new flow.VehicleExpense(null, book);
        break;
      case 'Deposit': instance = new flow.Deposit(null, book);
        break;
      case 'Withdrawal': instance = new flow.Withdrawal(null, book);
        break;
      case 'Repayment': instance = new flow.Repayment(null, book);
        break;
      case 'Borrowing': instance = new flow.Borrowing(null, book);
        break;
      case 'Transfer': instance = new flow.Transfer(null, book);
        break;
      case 'Asset': instance = new asset.Asset(null, book);
        break;
      case 'Savings': instance = new asset.Savings(null, book);
        break;
      case 'Investment': instance = new asset.Investment(null, book);
        break;
      case 'Insurance': instance = new asset.Insurance(null, book);
        break;
      case 'ProvidentFund': instance = new asset.ProvidentFund(null, book);
        break;
      case 'Annuity': instance = new asset.Annuity(null, book);
        break;
      case 'Property': instance = new asset.Property(null, book);
        break;
      case 'Liability': instance = new liability.Liability(null, book);
        break;
      case 'MonthlyRestLoan': instance = new liability.MonthlyRestLoan(null, book);
        break;
      case 'FlatRateLoan': instance = new liability.FlatRateLoan(null, book);
        break;
      default: throw new Error('Error: cannot create instance of this type: ' + editingInstanceClass);
    }


    return instance;
  }
  // addInstance(instance) {
  //   const uuid = Guid.create().toString();
  //   instance.setId(uuid);
  // }



  createAndAddInstance(editingInstanceClass: string, book: Book) {
    // const book = this.getPlan().getBook();
    const instance = this.createInstance(editingInstanceClass, book);
    book.addInstance(instance);

    return instance;
  }


  createNewPlan(person: Profile, name: string, startDate: Date, initialAmount: number, periodCount: number) {
    const plan = this.newPlan(person, 'Plan 1', startDate, 0, periodCount);
    this.setPlan(plan);
    return this.plan;
  }
  newPlan(proflle: Profile, name: string, startDate: Date, initialAmount: number, periodCount: number) {
    this.profile = proflle;
    const plan = this.createPlan('Plan 1', startDate, 0, periodCount);
    return plan;
  }
  // Create a prototype plan as a starting plan
  newPrototypePlan() {
    const birthDate = new Date(1973, 1, 1);

    const person = new Profile('OBT', birthDate);



    // const plan = planner.createPlan('Plan 1', startDate, 0, 40);

    const startDate = new Date(2019, 1, 1);
    const plan = this.newPlan(person, 'Plan 1', startDate, 0, 1);
    plan.init();

    const book = plan.getBook();
    const schedule = plan.getSchedule();

    const preventSignChange = true;
    const savings = this.createAndAddInstance('Savings', book);
    savings.setStateValue({
      name: 'Savings', initialAmount: 50000, changeRate: 0.01,
      startPeriodNumber: schedule.getYear(0), autoWithdrawalPriority: 100
    });

    const endowment = this.createAndAddInstance('Savings', book);
    endowment.setStateValue({
      name: 'Endowment', initialAmount: 15000, changeRate: 0.045,
      startPeriodNumber: schedule.getYear(0)
    });

    const investment = this.createAndAddInstance('Investment', book);
    investment.setStateValue({
      name: 'Investment', initialAmount: 150000, changeRate: 0.04,
      startPeriodNumber: schedule.getYear(0), autoWithdrawalPriority: 50
    });

    const providentFund = this.createAndAddInstance('ProvidentFund', book);
    providentFund.setStateValue({
      name: 'CPF', initialAmount: 450000, changeRate: 0.03,
      startPeriodNumber: schedule.getYear(0)
    });

    const annuity = this.createAndAddInstance('Annuity', book);
    annuity.setStateValue({
      name: 'Annuity', initialAmount: 0, changeRate: 0.03,
      startPeriodNumber: schedule.getYear(0)
    });

    const mortageLoan = this.createAndAddInstance('MonthlyRestLoan', book);
    mortageLoan.setStateValue({
      name: 'Mortage Loan', initialAmount: 250000, changeRate: 0.025,
      startPeriodNumber: schedule.getYear(0)
    });

    const carLoan = this.createAndAddInstance('FlatRateLoan', book);
    carLoan.setStateValue({
      name: 'Car Loan', initialAmount: 10000, changeRate: 0.025,
      startPeriodNumber: schedule.getYear(2), tenure: 10
    });


    const income = this.createAndAddInstance('Income', book);
    income.setStateValue({
      name: 'Salary', initialAmount: 7500 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(5)
    });

    const expenseWorking = this.createAndAddInstance('ItemizedExpense', book);
    expenseWorking.setStateValue({
      name: 'Itemized Expenses (Working)', initialAmount: 3000 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(5)
    });

    const expenseRetired = this.createAndAddInstance('ItemizedExpense', book);
    expenseRetired.setStateValue({
      name: 'Itemized Expenses (Retired)', initialAmount: 2000 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(6), endPeriodNumber: schedule.getEndYear()
    });

    const carLoanRepayment = this.createAndAddInstance('Repayment', book);
    carLoanRepayment.setStateValue({
      name: 'Repayment to Car Loan', initialAmount: 104 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(2), endPeriodNumber: schedule.getYear(11), target: carLoan
    });



    /**/

    const providentFundDeposit = this.createAndAddInstance('Deposit', book);
    providentFundDeposit.setStateValue({
      name: 'Deposit to CPF', initialAmount: 2000 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(5), target: providentFund
    });


    const transferProvidentFundToAnnualty = this.createAndAddInstance('Transfer', book);
    transferProvidentFundToAnnualty.setStateValue({
      name: 'Transfer from CPF to CPF Life', initialAmount: 300000, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(10), target: annuity, source: providentFund
    });

    const providentFundWithdrawal = this.createAndAddInstance('Withdrawal', book);
    providentFundWithdrawal.setStateValue({
      name: 'Withdrawal from CPF', initialAmount: 999999999, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(10), source: providentFund
    });


    const investmentWithdrawal = this.createAndAddInstance('Withdrawal', book);
    investmentWithdrawal.setStateValue({
      name: 'Withdrawal from Investment (Closure)', initialAmount: 99999999, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(14), source: investment
    });


    const mortageLoanRepayment = this.createAndAddInstance('Repayment', book);
    mortageLoanRepayment.setStateValue({
      name: 'Repayment to Mortage Loan', initialAmount: 1500 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getEndYear(), target: mortageLoan
    });

    const annualtyWithdrawal = this.createAndAddInstance('Withdrawal', book);
    annualtyWithdrawal.setStateValue({
      name: 'Withdrawal from Annuity', initialAmount: 2500 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(20), endPeriodNumber: schedule.getEndYear(), source: annuity
    });


    return plan;
  }
  createPlan1() {

    const birthDate = new Date(1973, 1, 1);

    const person = new Profile('OBT', birthDate);



    // const plan = planner.createPlan('Plan 1', startDate, 0, 40);

    const startDate = new Date(2019, 1, 1);
    const plan = this.createNewPlan(person, 'Plan 1', startDate, 0, 40);


    const book = plan.getBook();
    const schedule = plan.getSchedule();

    this.plan = plan;

    const preventSignChange = true;
    const savings = this.createAndAddInstance('Savings', book);
    savings.setStateValue({
      name: 'Savings', initialAmount: 50000, changeRate: 0.01,
      startPeriodNumber: schedule.getYear(0), autoWithdrawalPriority: 100
    });

    const endowment = this.createAndAddInstance('Savings', book);
    endowment.setStateValue({
      name: 'Endowment', initialAmount: 15000, changeRate: 0.045,
      startPeriodNumber: schedule.getYear(0)
    });

    const investment = this.createAndAddInstance('Investment', book);
    investment.setStateValue({
      name: 'Investment', initialAmount: 150000, changeRate: 0.04,
      startPeriodNumber: schedule.getYear(0), autoWithdrawalPriority: 50
    });

    const insurance = this.createAndAddInstance('Insurance', book);
    insurance.setStateValue({
      name: 'Insurance', initialAmount: 10000, changeRate: 0.03,
      startPeriodNumber: schedule.getYear(0)
    });

    const providentFund = this.createAndAddInstance('ProvidentFund', book);
    providentFund.setStateValue({
      name: 'Provident Fund', initialAmount: 450000, changeRate: 0.03,
      startPeriodNumber: schedule.getYear(0)
    });

    const annuity = this.createAndAddInstance('Annuity', book);
    annuity.setStateValue({
      name: 'Annuity', initialAmount: 0, changeRate: 0.03,
      startPeriodNumber: schedule.getYear(0)
    });

    const mortageLoan = this.createAndAddInstance('MonthlyRestLoan', book);
    mortageLoan.setStateValue({
      name: 'Mortage Loan', initialAmount: 250000, changeRate: 0.025,
      startPeriodNumber: schedule.getYear(0)
    });

    const carLoan = this.createAndAddInstance('FlatRateLoan', book);
    carLoan.setStateValue({
      name: 'Car Loan', initialAmount: 10000, changeRate: 0.025,
      startPeriodNumber: schedule.getYear(2), tenure: 10
    });


    const income = this.createAndAddInstance('Income', book);
    income.setStateValue({
      name: 'General Income', initialAmount: 7500 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(5)
    });

    const expenseWorking = this.createAndAddInstance('Expense', book);
    expenseWorking.setStateValue({
      name: 'General Expenses (Working)', initialAmount: 3000 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(5)
    });

    const expenseRetired = this.createAndAddInstance('Expense', book);
    expenseRetired.setStateValue({
      name: 'General Expenses (Retired)', initialAmount: 2000 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(6), endPeriodNumber: schedule.getEndYear()
    });

    const carLoanRepayment = this.createAndAddInstance('Repayment', book);
    carLoanRepayment.setStateValue({
      name: 'Repayment to Car Loan', initialAmount: 104 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(2), endPeriodNumber: schedule.getYear(11), target: carLoan
    });


    const carPurchase = this.createAndAddInstance('Expense', book);
    carPurchase.setStateValue({
      name: 'Car Down Payment', initialAmount: 50000, changeRate: 0,
      startPeriodNumber: schedule.getYear(2), endPeriodNumber: schedule.getYear(2)
    });

    const carExpense = this.createAndAddInstance('Expense', book);
    carExpense.setStateValue({
      name: 'Car Expenses', initialAmount: 500 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(2), endPeriodNumber: schedule.getYear(12)
    });


    /**/
    const savingsDeposit = this.createAndAddInstance('Deposit', book);
    savingsDeposit.setStateValue({
      name: 'Deposit to Savings', initialAmount: 500 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getEndYear(), target: savings
    });

    const providentFundDeposit = this.createAndAddInstance('Deposit', book);
    providentFundDeposit.setStateValue({
      name: 'Deposit to ProvidentFund', initialAmount: 2000 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(5), target: providentFund
    });


    const transferProvidentFundToAnnualty = this.createAndAddInstance('Transfer', book);
    transferProvidentFundToAnnualty.setStateValue({
      name: 'Transfer from ProvidentFund to Annuity', initialAmount: 300000, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(10), target: annuity, source: providentFund
    });

    const providentFundWithdrawal = this.createAndAddInstance('Withdrawal', book);
    providentFundWithdrawal.setStateValue({
      name: 'Withdrawal from ProvidentFund', initialAmount: 999999999, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(10), source: providentFund
    });

    const endowmentDeposit = this.createAndAddInstance('Deposit', book);
    endowmentDeposit.setStateValue({
      name: 'Deposit to Endowment', initialAmount: 1000, changeRate: 0,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(9), target: endowment
    });

    const endowmentWithdrawal = this.createAndAddInstance('Withdrawal', book);
    endowmentWithdrawal.setStateValue({
      name: 'Withdrawal from Endowment', initialAmount: 999999999, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(10), source: endowment
    });

    const investmentWithdrawal = this.createAndAddInstance('Withdrawal', book);
    investmentWithdrawal.setStateValue({
      name: 'Withdrawal from Investment', initialAmount: 200 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(14), source: investment
    });

    const investmentWithdrawal2 = this.createAndAddInstance('Withdrawal', book);
    investmentWithdrawal2.setStateValue({
      name: 'Withdrawal from Investment (Retired)', initialAmount: 5000 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(15), endPeriodNumber: schedule.getEndYear(), source: investment
    });

    const insuranceWithdrawal = this.createAndAddInstance('Withdrawal', book);
    insuranceWithdrawal.setStateValue({
      name: 'Withdrawal from Insurance (Closure)', initialAmount: 99999999, changeRate: 0,
      startPeriodNumber: schedule.getYear(20), endPeriodNumber: schedule.getYear(20), source: insurance
    });

    const mortageLoanRepayment = this.createAndAddInstance('Repayment', book);
    mortageLoanRepayment.setStateValue({
      name: 'Repayment to Mortage Loan', initialAmount: 1500 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getEndYear(), target: mortageLoan
    });

    const annualtyWithdrawal = this.createAndAddInstance('Withdrawal', book);
    annualtyWithdrawal.setStateValue({
      name: 'Withdrawal from Annuity', initialAmount: 2500 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(20), endPeriodNumber: schedule.getEndYear(), source: annuity
    });






    // book.getCash().setAutoWithdrawalSource(savings);
    console.log(book);
    this.setPlan(plan);
    return plan;
  }
  createPlan2() {

    const birthDate = new Date(1973, 1, 1);

    const person = new Profile('John Doe', birthDate);



    // const plan = planner.createPlan('Plan 1', startDate, 0, 40);

    const startDate = new Date(2020, 1, 1);
    const plan = this.createNewPlan(person, 'Plan 1', startDate, 0, 40);


    const book = plan.getBook();
    const schedule = plan.getSchedule();

    this.plan = plan;

    const preventSignChange = true;
    const savings = this.createAndAddInstance('Savings', book);
    savings.setStateValue({
      name: 'Savings', initialAmount: 50000, changeRate: 0.01,
      startPeriodNumber: schedule.getYear(0), autoWithdrawalPriority: 100
    });

    const endowment = this.createAndAddInstance('Savings', book);
    endowment.setStateValue({
      name: 'Endowment', initialAmount: 10000, changeRate: 0.045,
      startPeriodNumber: schedule.getYear(0)
    });

    const investment = this.createAndAddInstance('Investment', book);
    investment.setStateValue({
      name: 'Investment', initialAmount: 50000, changeRate: 0.04,
      startPeriodNumber: schedule.getYear(0), autoWithdrawalPriority: 50
    });

    const insurance = this.createAndAddInstance('Insurance', book);
    insurance.setStateValue({
      name: 'Insurance', initialAmount: 10000, changeRate: 0.03,
      startPeriodNumber: schedule.getYear(0)
    });

    const providentFund = this.createAndAddInstance('ProvidentFund', book);
    providentFund.setStateValue({
      name: 'Provident Fund', initialAmount: 200000, changeRate: 0.03,
      startPeriodNumber: schedule.getYear(0)
    });

    const annuity = this.createAndAddInstance('Annuity', book);
    annuity.setStateValue({
      name: 'Annuity', initialAmount: 0, changeRate: 0.03,
      startPeriodNumber: schedule.getYear(0)
    });

    const mortageLoan = this.createAndAddInstance('MonthlyRestLoan', book);
    mortageLoan.setStateValue({
      name: 'Mortage Loan', initialAmount: 100000, changeRate: 0.025,
      startPeriodNumber: schedule.getYear(0)
    });

    const carLoan = this.createAndAddInstance('FlatRateLoan', book);
    carLoan.setStateValue({
      name: 'Car Loan', initialAmount: 10000, changeRate: 0.025,
      startPeriodNumber: schedule.getYear(2), tenure: 10
    });


    const income = this.createAndAddInstance('Income', book);
    income.setStateValue({
      name: 'General Income', initialAmount: 5000 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(5)
    });

    const expenseWorking = this.createAndAddInstance('Expense', book);
    expenseWorking.setStateValue({
      name: 'General Expenses (Working)', initialAmount: 3000 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(5)
    });

    const expenseRetired = this.createAndAddInstance('Expense', book);
    expenseRetired.setStateValue({
      name: 'General Expenses (Retired)', initialAmount: 2000 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(6), endPeriodNumber: schedule.getEndYear()
    });

    const carLoanRepayment = this.createAndAddInstance('Repayment', book);
    carLoanRepayment.setStateValue({
      name: 'Repayment to Car Loan', initialAmount: 104 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(2), endPeriodNumber: schedule.getYear(11), target: carLoan
    });


    const carPurchase = this.createAndAddInstance('Expense', book);
    carPurchase.setStateValue({
      name: 'Car Down Payment', initialAmount: 50000, changeRate: 0,
      startPeriodNumber: schedule.getYear(2), endPeriodNumber: schedule.getYear(2)
    });

    const carExpense = this.createAndAddInstance('Expense', book);
    carExpense.setStateValue({
      name: 'Car Expenses', initialAmount: 500 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(2), endPeriodNumber: schedule.getYear(12)
    });


    /**/
    const savingsDeposit = this.createAndAddInstance('Deposit', book);
    savingsDeposit.setStateValue({
      name: 'Deposit to Savings', initialAmount: 500 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getEndYear(), target: savings
    });

    const providentFundDeposit = this.createAndAddInstance('Deposit', book);
    providentFundDeposit.setStateValue({
      name: 'Deposit to ProvidentFund', initialAmount: 1200 * 12, changeRate: 0.02,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(5), target: providentFund
    });


    const transferProvidentFundToAnnualty = this.createAndAddInstance('Transfer', book);
    transferProvidentFundToAnnualty.setStateValue({
      name: 'Transfer from ProvidentFund to Annuity', initialAmount: 200000, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(10), target: annuity, source: providentFund
    });

    const providentFundWithdrawal = this.createAndAddInstance('Withdrawal', book);
    providentFundWithdrawal.setStateValue({
      name: 'Withdrawal from ProvidentFund', initialAmount: 999999999, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(10), source: providentFund
    });

    const endowmentDeposit = this.createAndAddInstance('Deposit', book);
    endowmentDeposit.setStateValue({
      name: 'Deposit to Endowment', initialAmount: 1000, changeRate: 0,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getYear(9), target: endowment
    });

    const endowmentWithdrawal = this.createAndAddInstance('Withdrawal', book);
    endowmentWithdrawal.setStateValue({
      name: 'Withdrawal from Endowment', initialAmount: 999999999, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(10), source: endowment
    });

    const investmentWithdrawal = this.createAndAddInstance('Withdrawal', book);
    investmentWithdrawal.setStateValue({
      name: 'Withdrawal from Investment', initialAmount: 200 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(10), endPeriodNumber: schedule.getYear(14), source: investment
    });

    const investmentWithdrawal2 = this.createAndAddInstance('Withdrawal', book);
    investmentWithdrawal2.setStateValue({
      name: 'Withdrawal from Investment (Retired)', initialAmount: 5000 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(15), endPeriodNumber: schedule.getEndYear(), source: investment
    });

    const insuranceWithdrawal = this.createAndAddInstance('Withdrawal', book);
    insuranceWithdrawal.setStateValue({
      name: 'Withdrawal from Insurance (Closure)', initialAmount: 99999999, changeRate: 0,
      startPeriodNumber: schedule.getYear(20), endPeriodNumber: schedule.getYear(20), source: insurance
    });

    const mortageLoanRepayment = this.createAndAddInstance('Repayment', book);
    mortageLoanRepayment.setStateValue({
      name: 'Repayment to Mortage Loan', initialAmount: 1500 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(0), endPeriodNumber: schedule.getEndYear(), target: mortageLoan
    });

    const annualtyWithdrawal = this.createAndAddInstance('Withdrawal', book);
    annualtyWithdrawal.setStateValue({
      name: 'Withdrawal from Annuity', initialAmount: 2500 * 12, changeRate: 0,
      startPeriodNumber: schedule.getYear(20), endPeriodNumber: schedule.getEndYear(), source: annuity
    });






    // book.getCash().setAutoWithdrawalSource(savings);
    console.log(book);
    this.setPlan(plan);
    return plan;
  }
  createPlan0() {
    const startDate = new Date(2019, 1, 1);
    const birthDate = new Date(1973, 1, 1);

    const profile = new Profile('OBT', birthDate);

    this.profile = profile;
    const plan = this.createPlan('Plan 1', startDate, 0, 40);

    const book = plan.getBook();
    const schedule = plan.getSchedule();

    const preventSignChange = true;
    const savings = new asset.Savings('Savings', book, 50000, 0.01, preventSignChange, schedule.getYear(0)); book.addAsset(savings);
    savings.setAutoWithdrawalPriority(100);

    const endowment = new asset.Savings('Endowment', book, 15000, 0.045, preventSignChange, schedule.getYear(0)); book.addAsset(endowment);
    const investment = new asset.Investment('Investment', book, 150000, 0.04, schedule.getYear(0)); book.addAsset(investment);
    investment.setAutoWithdrawalPriority(50);

    const insurance = new asset.Insurance('Insurance', book, 10000, 0.03, schedule.getYear(0)); book.addAsset(insurance);
    const providentFund = new asset.ProvidentFund('Provident Fund', book, 450000, 0.03, schedule.getYear(0)); book.addAsset(providentFund);
    const annuity = new asset.Annuity('Annuity', book, 0, 0.03, schedule.getYear(0)); book.addAsset(annuity);
    const mortageLoan = new liability.MonthlyRestLoan('Mortage Loan', book, 250000, 0.025, schedule.getYear(0)); book.addLiability(mortageLoan);

    const income = new flow.Income('General Income', book, 7500 * 12, schedule.getYear(0), schedule.getYear(5), 0.02); // changeRate is 3%
    book.addFlow(income);


    const expenseWorking = new flow.Expense('General Expenses (Working)', book, 3000 * 12, schedule.getYear(0), schedule.getYear(5), 0.02); // changeRate is 3%
    book.addFlow(expenseWorking);
    const expenseRetired = new flow.Expense('General Expenses (Retired)', book, 2000 * 12, schedule.getYear(6), schedule.getEndYear(), 0.02); // changeRate is 3%
    book.addFlow(expenseRetired);

    const carLoan = new liability.FlatRateLoan('Car Loan', book, 10000, 0.025, 10, schedule.getYear(2)); book.addLiability(carLoan);
    const carLoanRepayment = new flow.Repayment('Repayment to Car Loan', book, 104 * 12, schedule.getYear(2), schedule.getYear(11), 0, carLoan);
    book.addFlow(carLoanRepayment);
    const carPurchase = new flow.Expense('Car Down Payment', book, 50000, schedule.getYear(2), schedule.getYear(2), 0); // changeRate is 3%
    book.addFlow(carPurchase);
    const carExpense = new flow.Expense('Car Expenses', book, 500 * 12, schedule.getYear(2), schedule.getYear(12), 0.02); // changeRate is 3%
    book.addFlow(carExpense);
    /**/

    const savingsDeposit = new flow.Deposit('Deposit to Savings', book, 500 * 12, schedule.getYear(0), schedule.getEndYear(), 0, savings);
    book.addFlow(savingsDeposit);
    const providentFundDeposit = new flow.Deposit('Deposit to ProvidentFund', book, 2000 * 12, schedule.getYear(0), schedule.getYear(5), 0, providentFund);
    book.addFlow(providentFundDeposit);
    const transferProvidentFundToAnnualty = new flow.Transfer('Transfer from ProvidentFund to Annuity', book, 300000, schedule.getYear(10), schedule.getYear(10), providentFund, annuity);
    book.addFlow(transferProvidentFundToAnnualty);
    const providentFundWithdrawal = new flow.Withdrawal('Withdrawal from ProvidentFund', book, 999999999, schedule.getYear(10), schedule.getYear(10), 0, providentFund);
    book.addFlow(providentFundWithdrawal);


    const endowmentDeposit = new flow.Deposit('Deposit to Endowment', book, 1000, schedule.getYear(0), schedule.getYear(9), 0, endowment);
    book.addFlow(endowmentDeposit);
    const endowmentWithdrawal = new flow.Withdrawal('Withdrawal from Endowment', book, 99999999, schedule.getYear(10), schedule.getYear(10), 0, endowment);
    book.addFlow(endowmentWithdrawal);

    const investmentWithdrawal = new flow.Withdrawal('Withdrawal from Investment', book, 200 * 12, schedule.getYear(10), schedule.getYear(14), 0, investment);
    book.addFlow(investmentWithdrawal);
    const investmentWithdrawal2 = new flow.Withdrawal('Withdrawal from Investment (Retired)', book, 5000 * 12, schedule.getYear(15), schedule.getEndYear(), 0, investment);
    book.addFlow(investmentWithdrawal2);


    const insuranceWithdrawal = new flow.Withdrawal('Withdrawal from Insurance (Closure)', book, 99999999, schedule.getYear(20), schedule.getYear(20), 0, insurance);
    book.addFlow(insuranceWithdrawal);

    const mortageLoanRepayment = new flow.Repayment('Repayment to Mortage Loan', book, 1500 * 12, schedule.getYear(0), schedule.getEndYear(), 0, mortageLoan);
    book.addFlow(mortageLoanRepayment);

    const annualtyWithdrawal = new flow.Withdrawal('Withdrawal from Annuity', book, 2500 * 12, schedule.getYear(20), schedule.getEndYear(), 0, annuity);
    book.addFlow(annualtyWithdrawal);



    // book.getCash().setAutoWithdrawalSource(savings);
    console.log(book);
    this.setPlan(plan);
    return plan;
  }
  getPlan() {
    return this.plan;
  }
  processTimeline(retirementAge: number) {
    const plan = this.plan;

    const timeline = plan.getTimeline(); // get a new timeline

    const profile = this.getProfile();
    const book = plan.getBook();
    const retirementPeriod = plan.getSchedule().getYearByAge(retirementAge);


    timeline.init(retirementPeriod);

    for (let periodNumber = 0; periodNumber < plan.getSchedule().periodCount; periodNumber++) {
      timeline.forward();
    };

    // console.log(timeline.states);

    const data = this.convertToJson(plan.getBook(), timeline)
    return data;
  }
  private formatPeriod(period: Period, periodCount: number) {
    return period.age;
    // return 'Period: ' + periodCount + ', Age: ' + period.age + ', Year: ' + period.year;
  }
  private convertToJson(book: Book, timeline: Timeline) {
    const netWorthData = [];
    netWorthData['columns'] = ['Period'];

    netWorthData['columns'].push(...Array.from(new Set(Array.from(book.getActiveAssets()).map(obj => obj.getName()))));
    netWorthData['columns'].push(...Array.from(new Set(Array.from(book.getActiveLiabilities()).map(obj => obj.getName()))));
    netWorthData['columns'].push('Net Worth');
    netWorthData['columns'].push(book.getCash().getName());


    let periodCount = 0;
    timeline.getPeriods().forEach(period => {
      const state = timeline.states.get(period.year);
      const row = {};
      row['Period'] = this.formatPeriod(period, periodCount);
      let netWorth = 0;
      let totalAssets = 0;
      let totalLiabilities = 0;
      book.getActiveAssets().forEach(asset => {
        const accumulation = state.get(asset);
        totalAssets += accumulation.amount;
        row[asset.getName()] = accumulation.amount.toFixed(0);
      });
      book.getActiveLiabilities().forEach(liability => {
        const accumulation = state.get(liability);
        totalLiabilities += accumulation.amount;
        row[liability.getName()] = -accumulation.amount.toFixed(0);
      });

      netWorth = totalAssets - totalLiabilities;

      const assetState = state.get(book.getCash());
      row[book.getCash().getName()] = assetState.amount.toFixed(0);
      netWorth += assetState.amount;

      row['Net Worth'] = netWorth.toFixed(0);
      row['Total Assets'] = totalAssets.toFixed(0);
      row['Total Liabilities'] = totalLiabilities.toFixed(0);

      netWorthData.push(row);

      periodCount++;
    });


    const cashFlowData = [];
    cashFlowData['columns'] = ['Period'];


    cashFlowData['columns'].push(book.getCash().getName());
    cashFlowData['columns'].push('Net Flow');

    const flowNames = new Set();

    periodCount = 0;
    timeline.getPeriods().forEach(period => {
      const state = timeline.states.get(period.year);
      const row = {};
      row['Period'] = this.formatPeriod(period, periodCount);

      const cashState = state.get(book.getCash());
      row[book.getCash().getName()] = cashState.amount.toFixed(0);

      row['Net Flow'] = 0;
      let netFlow = 0;
      let totalInFlows = 0;
      let totalOutFlows = 0;
      cashState.transactions.forEach(transaction => {

        flowNames.add(transaction.flow.name); // collect unique flow names for charting later
        if (transaction.name === 'flowFrom') {
          totalOutFlows += transaction.amount;
          row[transaction.flow.name] = -transaction.amount.toFixed(0);
          // row['Net Flow'] -= totalInFlows;
        } else {

          totalInFlows += transaction.amount;
          row[transaction.flow.name] = transaction.amount.toFixed(0);
          // row['Net Flow'] += totalOutFlows;
        }

      });

      netFlow = totalOutFlows - totalInFlows;

      row['Net Flow'] = netFlow.toFixed(0);
      row['In Flows'] = totalInFlows.toFixed(0);
      row['Out Flows'] = totalOutFlows.toFixed(0);
      cashFlowData.push(row);

      periodCount++;
    });
    cashFlowData['columns'].push(...Array.from(flowNames));



    const data = [];
    data['netWorthData'] = netWorthData;
    data['cashFlowData'] = cashFlowData;
    return data;
  }

}
