

import { Profile } from './profile.model'
import { Flow } from './flows.model'
import { Book } from './book.model'
import { Guid } from 'guid-typescript';
import { IInstance, IBook } from './instance.model';
import { Exclude } from 'class-transformer';

declare const Queue: any;

export abstract class Accumulation extends IInstance {

    protected polarity: number; // in
    protected category: string;
    protected preventSignChange: boolean; // in

    @Exclude()
    protected transactions: Array<any>; // in
    public autoWithdrawalPriority: number; // in



    constructor(name: string, book: IBook, polarity: number, initialAmount: number = 0,
        changeRate: number = 0, preventSignChange: boolean = true, startPeriodNumber: number = 0) {
        // Chain constructor with super
        super(name, book, initialAmount, startPeriodNumber, 9999, changeRate);

        this.transactions = [];

        this.category = 'Accumulation';
        this.polarity = polarity;

        this.preventSignChange = preventSignChange;

        this.setEndPeriod(9999);
        this.autoWithdrawalPriority = 0;

    }
    flowTo(v: number, flow: Flow) {
        this.amount += this.polarity * v;

        this.transactions.push({ name: 'flowTo', amount: v, flow: flow });
        return v;
    }
    flowFrom(v: number, flow: Flow) {
        this.amount += -this.polarity * v;
        if (this.amount < 0) {
            if (this.preventSignChange) {
                this.amount = 0;
            }
        }
        this.transactions.push({ name: 'flowFrom', amount: v, flow: flow });
        return v;
    }
    allowableFlowTo(v: number, flow: Flow) {
        let flowableAmount = v;
        if (this.preventSignChange) {
            if (this.amount + (this.polarity * flowableAmount) < 0) {
                flowableAmount = this.amount;
            }
        }
        return flowableAmount;
    }
    allowableFlowFrom(v: number, flow: Flow) {
        let flowableAmount = v;
        if (this.preventSignChange) {
            if (this.amount - (this.polarity * flowableAmount) < 0) {
                flowableAmount = this.amount;
            }
        }
        return flowableAmount;
    }
    /// process() and init() must return the same class
    beforeProcess(previousState, currentPeriodNumber: number, retirementPeriod: number) {
        this.transactions = []; // clear as this is a current state object

        if (this.getAdjustedStartPeriodNumber(retirementPeriod) === currentPeriodNumber) {
            this.init();
        } else if (currentPeriodNumber >= this.getAdjustedStartPeriodNumber(retirementPeriod) && currentPeriodNumber <= this.getAdjustedEndPeriodNumber(retirementPeriod)) {
            this.amount = previousState.amount;
        } else {
            this.amount = 0; // not within the start end period
        }

    }
   
    process(currentPeriodNumber: number, retirementPeriod: number) {
        // Just grow it as a start
        if (currentPeriodNumber >= this.getAdjustedStartPeriodNumber(retirementPeriod) && currentPeriodNumber <= this.getAdjustedEndPeriodNumber(retirementPeriod)) {
            this.amount = this.amount * (1 + this.changeRate);
        } else {
            this.amount = 0; // not within the start end period
        }
    }

    getState() {
        return { transactions: this.transactions, amount: this.amount };
    }
    /// process() and init() must return the same class
    init() {
        this.amount = this.initialAmount;
    }
    toString() {
        return `Accumulation: ${this.name}, amount: $${this.amount}.`;
    }



}
