import { Component } from '@angular/core';
import { AuthService } from 'app/core/auth.service';

import { Router } from '@angular/router';
import { PlannerService } from 'app/core/planner/planner-service';

@Component({
  selector: 'home-cmp',
  moduleId: module.id,
  templateUrl: 'home.component.html'
})

export class HomeComponent {
  startText='Go to demo';
  constructor(private plannerService: PlannerService, private router: Router, private authService: AuthService) {

  }
  createSamplePlan() {
    this.startText='Generating a sample plan. Please wait...';
    this.plannerService.createPlan2();

    this.router.navigate(['plan/dashboard']);
  }

  signInWithGoogle() {
    this.authService.signInWithGoogle()
        .then((res) => {
            this.router.navigate(['plan/dashboard'])
        })
        .catch((err) => console.log(err));
}
}
