import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AuthGuardService } from './auth-guard.service';
import { DataService } from './data.service';
import { PlannerService } from './planner/planner-service';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { SideBarService } from 'app/sidebar/sidebar.service';
import { SidebarModule } from 'primeng/sidebar';

@NgModule({
  imports: [HttpClientModule, SidebarModule]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [AuthService, AuthGuardService, DataService, PlannerService, CanDeactivateGuard, SideBarService]
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

}

export { AuthService, AuthGuardService, DataService, PlannerService };
