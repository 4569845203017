import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Plan } from 'app/shared/models/planner.model';
import { Book } from 'app/shared/models/book.model';
import { InstanceProxy } from 'app/shared/models/repository.model';
import { plainToClass, classToPlain } from "class-transformer";
import 'core-js/es7/object';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    // https://www.techiediaries.com/angular-firestore-tutorial/
    // https://angular-templates.io/tutorials/about/angular-crud-with-firebase


    private instanceCache = new WeakSet();
    constructor(public db: AngularFirestore) { }

    // getAvatars() {
    //     return this.db.collection('/avatar').valueChanges()
    // }

    getProfile(profileId) {
        return this.db.collection('profiles').doc(profileId).snapshotChanges();

    }

    updateProfile(profileId, value) {
        value.nameToSearch = value.name.toLowerCase();
        return this.db.collection('profiles').doc(profileId).set(value);

        // delete policy.id;
        // this.firestore.doc('policies/' + policy.id).update(policy);
    }

    deleteProfile(profileId) {
        return this.db.collection('profiles').doc(profileId).delete();
        // this.firestore.doc('policies/' + policyId).delete();
    }

    getProfiles() {
        return this.db.collection('profiles').snapshotChanges();
        // return this.firestore.collection('policies').snapshotChanges();
    }

    searchProfiles(searchValue) {
        return this.db.collection('profiles', ref => ref.where('nameToSearch', '>=', searchValue)
            .where('nameToSearch', '<=', searchValue + '\uf8ff'))
            .snapshotChanges()
    }

    // searchProfilesByAge(value) {
    //     return this.db.collection('profiles', ref => ref.orderBy('age').startAt(value)).snapshotChanges();
    // }


    createProfile(value, avatar) {
        return this.db.collection('profiles').add({
            name: value.name,
            nameToSearch: value.name.toLowerCase(),
            //   surname: value.surname,
            retirementAge: value.retirementAge,
            birthDate: new Date()
            //   avatar: avatar
        });
        // return this.firestore.collection('policies').add(policy);
    }


    // https://stackoverflow.com/questions/48541270/how-to-add-document-with-custom-id-to-firestore-angular
    createProfileWithUid(uid, value) {
        return this.db.collection('profiles').doc(uid).set({
            name: value.name,
            nameToSearch: value.name.toLowerCase(),
            //   surname: value.surname,
            retirementAge: value.retirementAge,
            birthDate: new Date()
            //   avatar: avatar
        });
        // return this.firestore.collection('policies').add(policy);
    }
    private getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    };


    // traverse(jsonObj, indent) {
    //     let s = {};
    //     if (jsonObj !== null && typeof jsonObj == 'object') {
    //         indent++;
    //         Object.entries(jsonObj).forEach(([key, value]) => {
    //             // key is either an array index or object key
    //             if ((value.getStateValue) && this.instanceCache.has(value)) {
    //                 s[key + '__Ref'] = value.getId();

    //             } else {
    //                 console.log(' '.repeat(indent) + key + ':');
    //                 this.instanceCache.add(value);
    //                 s[key] = this.traverse(value, indent);
    //             }
    //         });

    //     } else {
    //         // jsonObj is a number or string
    //         // return jsonObj + '';
    //         console.log(' '.repeat(indent) + '{' + jsonObj + '}');
    //         return jsonObj;
    //     }
    //     return s;
    // }
    // https://stackoverflow.com/questions/48873465/how-to-add-sub-collection-to-a-document-in-firestore
    createPlan0(profileId, plan: Plan) {
        const planObject = Object.assign({}, plan);
        let bookObject = plan.getBook().getStateValue();
        const bookInstanceProxy = new InstanceProxy(plan.getBook(), plan);
        // const o = this.traverse(bookObject, 0);
        bookObject = JSON.parse(JSON.stringify(bookObject, this.getCircularReplacer()));
        const b = classToPlain(plan.getSchedule());
        this.db.collection('profiles').doc(profileId).collection('plans').add({
            book: bookObject,
            name: plan.getName(),
            startDate: plan.getStartDate(),
            initialAmount: plan.getInitialAmount()
        }).catch(reason => {
            throw new Error(reason);
        });


    }
    private async insertPlan(profileId, plan: Plan): Promise<boolean> {
        const planPlain = classToPlain(plan);
        return this.db.collection('profiles').doc(profileId).collection('plans').add(planPlain)
            .then(result => {
                console.log(result);
                return true;
            })
            .catch(reason => {
                throw new Error(reason);
            });


    }
    private async getPlan(profileId): Promise<Plan> {
        return this.db.collection('profiles').doc(profileId).collection('plans').get().toPromise()
            .then(result => {
                console.log(result);
                if (result.docs.length < 1) {
                    Promise.reject(new Error('No record'));
                    // throw new Error('No record');
                }
                const plan = result.docs[0].data();
                const planObject = plainToClass(Plan, plan);
                return planObject;

            }
            );

    }

    async insertPlanWithId(profileId, plan: Plan): Promise<boolean> {
        const planPlain = classToPlain(plan);
        return this.db.collection('profiles').doc(profileId).collection('plans').doc('PLAN').set(planPlain)
            .then(result => {
                console.log(result);
                return true;
            })
            .catch(reason => {
                throw new Error(reason);
            });


    }
    async getPlanWithId(profileId): Promise<Plan> {
        return this.db.collection('profiles').doc(profileId).collection('plans').doc('PLAN').get().toPromise()
            .then(result => {
                console.log(result);
                const plan = result.data();
                const planObject = plainToClass(Plan, plan);
                return planObject;

            }).catch((error) => {
                return error;
            }
            );

    }
}
