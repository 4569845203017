
import { Flow } from './flows.model'
import { Accumulation } from './accumulation.model'
import { IBook } from './instance.model';


export class Asset extends Accumulation {
    public autoWithdrawalPriority = 0;

    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, preventSignChange: boolean = true, startPeriodNumber: number = 0, autoWithdrawalPriority: number = 0) {
        super(name, book, +1, initialAmount, changeRate, preventSignChange, startPeriodNumber);
        this.category = 'Asset';
        this.flowType = 'Asset';
    }
    flowFrom(v: number, flow: Flow) {
        const flowableAmount = v;
        /*
        if (this.preventSignChange) {
            if (this.amount - flowableAmount < 0) {
                flowableAmount = this.amount;
            }
        }
        */
        return super.flowFrom(flowableAmount, flow);
    }
}

export abstract class LiquidAsset extends Asset {

    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, preventSignChange: boolean = true, startPeriodNumber: number = 0) {
        super(name, book, initialAmount, changeRate, preventSignChange, startPeriodNumber);
        // this.category = 'LiquidAsset';
        this.setAutoWithdrawalPriority(0);
    }
    setAutoWithdrawalPriority(autoWithdrawalPriority) {
        this.autoWithdrawalPriority = autoWithdrawalPriority;
    }
    getStateSchema() {
        const schema = super.getStateSchema();
        schema['autoWithdrawalPriority'] = {
            type: 'integer',
            title: 'Allow Auto-Withdrawal if Cash falls below zero. (Larger equals higher priority; zero means no auto-withdrawal)',
            required: true,
            minimum: 0,
            maximum: +999
        };
        return schema;
    }
    getStateValue() {
        const values = super.getStateValue();
        values['autoWithdrawalPriority'] = this.autoWithdrawalPriority;
        return values;
    }
    setStateValue(values) {
        super.setStateValue(values);
        if (values.autoWithdrawalPriority !== undefined) {
            this.autoWithdrawalPriority = values.autoWithdrawalPriority;
        }
    }
}
export class Savings extends LiquidAsset {
    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, preventSignChange: boolean = true, startPeriodNumber: number = 0) {
        super(name, book, initialAmount, changeRate, preventSignChange, startPeriodNumber);
        // this.category = 'Savings';
    }

}
/// Cash is a special account that integrates the in/out flows to/from the other Accumulations
export class Cash extends LiquidAsset {

    constructor(name: string, book: IBook, initialAmount: number = 0, startPeriodNumber: number = 0) {
        super('Cash', book, initialAmount, 0, false, startPeriodNumber); // Cash has interest rate of 0%
        // this.category = 'Cash';
        // this.setStartPeriod(startPeriodNumber);

    }
    // getStateValue() {
    //     const values = super.getStateValue();
    //     values['initialAmount'] = this.initialAmount;
    //     values['startPeriodNumber'] = this.startPeriodNumber;
    //     return values;
    // }
    // setStateValue(values) {
    //     super.setStateValue(values);
    //     this.initialAmount = values.initialAmount;
    //     this.startPeriodNumber = values.startPeriodNumber;
    // }
}
export class Property extends Asset {

    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, startPeriodNumber: number = 0) {
        super(name, book, initialAmount, changeRate, true, startPeriodNumber);
        // this.category = 'Property';

        this.preventSignChange = true;
    }
}
export class Investment extends LiquidAsset {
    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, startPeriodNumber: number = 0) {
        super(name, book, initialAmount, changeRate, true, startPeriodNumber);
        // this.category = 'Investment';

        this.preventSignChange = true;
    }
}

export class Insurance extends LiquidAsset {
    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, startPeriodNumber: number = 0) {
        super(name, book, initialAmount, changeRate, true, startPeriodNumber);
        // this.category = 'Insurance';

        this.preventSignChange = true;
    }
    // flowTo(v: number, flow: Flow) {
    //     var flowableAmount = v;
    //     return super.flowTo(flowableAmount, flow);
    // }
}
export class ProvidentFund extends Savings {
    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, startPeriodNumber: number = 0) {
        super(name, book, initialAmount, changeRate, true, startPeriodNumber);
        // this.category = 'Provident Fund';
        this.preventSignChange = true;
    }
}
export class Annuity extends LiquidAsset {
    constructor(name: string, book: IBook, initialAmount: number = 0, changeRate: number = 0, startPeriodNumber: number = 0) {
        super(name, book, initialAmount, changeRate, true, startPeriodNumber);
        // this.category = 'Annuity';
        this.preventSignChange = true;
    }

    /// Annuity allows predetermined amount to flow from it no matter the balance
    allowableFlowFrom(v: number, flow: Flow) {
        const flowableAmount = v;
        return flowableAmount;
    }
}
